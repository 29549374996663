import React from "react";
import { Card } from "semantic-ui-react";
import {
    Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, MenuItem
} from "@material-ui/core";

const DialogBox = ({ onClose, onChange, onSubmit, onSubmitApprove, onSubmitRevoke, controller, data, valid }) => {

    const selectOptions = {
        TokenType: ["SG_RULE"],
        Type: ["INGRESS", "EGRESS"],
        IpProtocol: ["TCP", "UDP", "any"],
        FirewallRequest: ["PERMANENT", "TEMPORARY"]
    };

    function getTitle() {
        const subString =
          controller.createNewToken ? "create a new rule" :
          controller.deleteToken ? "delete this rule" :
          controller.editToken ? "edit this rule" :
          controller.keyPassword ? `${controller.keyPassword.action} this rule` :
          "Unknown action";
      
        return `Security Approval Request to ${subString}`;
      }
      

    return (
        <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            aria-labelledby="tokenForm-title"
            open={controller.createNewToken || controller.deleteToken || controller.editToken || !!controller.keyPassword}
        >
            <DialogTitle id="tokenForm-title">
                {
                    getTitle()
                }
            </DialogTitle>
            <DialogContent>
                <Card key="tokenForm">
                    <Card.Content>
                        {controller.keyPassword && (
                            <div>
                                {`Review to '${controller.keyPassword.action}'`}
                                <br />
                                <TextField label={`Private key password`} type="password" onChange={onChange("Password")} />
                            </div>
                        )}
                        {controller.deleteToken && (
                            <div>
                                <TextField id="delete-tokenid" label="TokenId" onChange={onChange("TokenId")} />
                                <TextField id="delete-JiraNumber" label="Jira Number"  onChange={onChange("JiraNumber")} />
                            </div>
                        )}
                        {(controller.createNewToken || controller.editToken) && (
                            <div>
                                <TextField
                                    select
                                    label="Token Type"
                                    helperText="Please select a Type"
                                    required
                                    onChange={onChange("TokenType")}
                                    value={data.TokenType}
                                    id="token-type-selector"
                                >
                                    {selectOptions.TokenType.map((r, i) => {
                                        return (
                                            <MenuItem value={r} key={i}>
                                                {r}
                                            </MenuItem>
                                        );
                                    })}
                                </TextField>
                                <br />
                                <TextField id="new-GroupName" label="GroupName" value={data.GroupName} onChange={onChange("GroupName")} />
                                <br />
                                <TextField
                                    select
                                    label="Rule Type"
                                    required
                                    onChange={onChange("Type")}
                                    value={data.Type}
                                    id="type-selector"
                                >
                                    {selectOptions.Type.map((r, i) => {
                                        return (
                                            <MenuItem value={r} key={i}>
                                                {r}
                                            </MenuItem>
                                        );
                                    })}
                                </TextField>
                                <br />
                                <TextField
                                    select
                                    label="Protocol"
                                    required
                                    onChange={onChange("IpProtocol")}
                                    value={data.IpProtocol}
                                    id="ipProtocol-selector"
                                >
                                    {selectOptions.IpProtocol.map((r, i) => {
                                        return (
                                            <MenuItem value={r} key={i}>
                                                {r}
                                            </MenuItem>
                                        );
                                    })}
                                </TextField>
                                <br />
                                <TextField
                                    select
                                    label="Firewall Request"
                                    required
                                    onChange={onChange("FirewallRequest")}
                                    value={data.FirewallRequest}
                                    id="firewallRequest-selector"
                                >
                                    {selectOptions.FirewallRequest.map((r, i) => {
                                        return (
                                            <MenuItem value={r} key={i}>
                                                {r}
                                            </MenuItem>
                                        );
                                    })}
                                </TextField>
                                <br />
                                <TextField id="token-target" label="Target" value={data.Target} placeholder="0.0.0.0 or regex" onChange={onChange("Target")} />
                                <br />
                                <TextField id="token-toPort" label="ToPort" value={data.ToPort} placeholder="22, 443 or 80..." onChange={onChange("ToPort")} />
                                <br />
                                <TextField id="new-JiraNumber" label="Jira Number" value={data.JiraNumber} onChange={onChange("JiraNumber")} />
                                <br />
                                <TextField
                                    id="token-description"
                                    label="Description"
                                    placeholder="HTTPS to web interface XXXX"
                                    value={data.Description}
                                    onChange={onChange("Description")}
                                />
                            </div>
                        )}
                    </Card.Content>
                </Card>
            </DialogContent>
            <DialogActions>
                <div>
                    <Button onClick={onClose} color="primary">Cancel</Button>

                    {valid && (
                        <Button
                            id="newRule-validation-button"
                            onClick={() => {
                                if (controller.keyPassword) {
                                    if (controller.keyPassword.action === "approve") {
                                        onSubmitApprove(controller.keyPassword.tokenData);
                                    } else if (controller.keyPassword.action === "revoke") {
                                        onSubmitRevoke(controller.keyPassword.tokenData);
                                    }
                                } else {
                                    onSubmit(controller.editToken ? "update" : controller.createNewToken ? "create" : "delete");
                                }
                            }}
                            color={controller.deleteToken ? "secondary" : "primary"}
                        >
                            Confirm
                        </Button>
                    )}
                </div>
            </DialogActions>
        </Dialog>
    );
}

export default DialogBox;