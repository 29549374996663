import React, { useState } from "react";
import LoadingPanel from "../common/LoadingPanel";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { Paper, TextField, Button, Switch } from "@material-ui/core";
import ObjectList from "../common/ObjectList";
import ReactMarkdown from "react-markdown";

const styles = theme => ({
  root: {
    padding: 20,
    marginTop: 20
  },
  columnContainer: {
    display: "flex",
    flexDirection: "row",
    maxWidth: 950,
    flexWrap: "wrap",
    width: "100%",
    justifyContent: "space-between"
  },
  centerContainer: {
    display: "flex",
    justifyContent: "center",
    margin: "20px"
  }
});

export const Formula = ({ formula = [] }) => {
  return (
    <div>
      {formula.map((f, i) => {
        return (
          <div id={i}>
            {f.description ? <label>{f.description}</label> : null}
            <p>{f.value}</p>
          </div>
        );
      })}
    </div>
  );
};

export const TeamResource = ({ infos }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {Object.keys(infos)
        .sort()
        .map((k, i) => {
          return (
            <div>
              {k.substr(0, 1).toUpperCase() + k.substr(1)} : {infos[k].toFixed(2)}
            </div>
          );
        })}
    </div>
  );
};

const SecurityProgram = ({ classes, history }) => {
  const { async, program, periodic, programItem } = useSelector(state => ({
    async: state.dashboard._async.SYNC_PROGRAM,
    program: state.dashboard.program || {
      variables: [],
      sections: [],
      defaultValues: {
        employees: 150,
        releases: 24,
        offices: 5,
        providers: 35,
        servers: 500,
        products: 4
      }
    },
    programItem: state.dashboard.programItem || {},
    periodic: state.dashboard.UIConfiguration.securityTable || []
  }));
  program.defaultValues = program.defaultValues || {};
  program.variables.forEach(v => {
    program.defaultValues[v] = program.defaultValues[v] !== undefined ? program.defaultValues[v] : 0;
  });
  const [variables, setVariables] = useState(program.defaultValues);
  const [disabled, setDisabled] = useState({});

  let total = 0;
  const jobs = {};
  variables.programs = Object.keys(variables).length - 1;
  program.sections.forEach(item => {
    item.computedCost = 0;
    if (item.cost) {
      item.cost.forEach(c => {
        try {
          // We use eval here, it is not super secure but data is not writable
          // Injection would be done through MD modification in the source code

          // eslint-disable-next-line
          item.computedCost += eval(eval("`" + c.value.replace(/\$\{/g, "${variables.") + "`"));
        } catch (err) {}
      });
      if (!disabled[item.id]) {
        total += item.computedCost;
      }
    }
    item.computedTeam = {};
    if (item.team) {
      item.team.forEach(c => {
        try {
          c.value
            .split("+")
            .map(f => f.trim())
            .forEach(form => {
              if (form.indexOf("/") < 0) {
                return;
              }
              let type = form.substr(0, form.indexOf("/"));
              form = form.substr(form.indexOf("/") + 1);
              // Number of hours per year (we round to 48 weeks because of PTO)
              // (50w * 5d * 8h)
              // eslint-disable-next-line
              const hourRatioPerYear = 1 / (48 * 5 * 8);
              // hour per month
              form = form.replace(/(\d+)hm/g, "$1 * hourRatioPerYear * 12");
              // hour per week
              form = form.replace(/(\d+)hw/g, "$1 * hourRatioPerYear * 52");
              // week per year (40h per week)
              form = form.replace(/(\d+)w/g, "$1 * hourRatioPerYear * 40");
              // year
              form = form.replace(/(\d+)y/g, "$1");
              // day per year (8h per day)
              form = form.replace(/(\d+)d/g, "$1 * hourRatioPerYear * 8");
              item.computedTeam[type] = item.computedTeam[type] ? item.computedTeam[type] : 0;
              // We use eval here, it is not super secure but data is not writable
              // Injection would be done through MD modification in the source code

              // eslint-disable-next-line
              let val = eval(eval("`" + form.replace(/\$\{/g, "${variables.") + "`"));
              item.computedTeam[type] += val;
              if (!disabled[item.id]) {
                jobs[type] = jobs[type] ? jobs[type] : 0;
                jobs[type] += val;
              }
            });
        } catch (err) {}
      });
    }
  });
  return (
    <Paper className={classes.root}>
      <Typography variant="h6" id="tableTitle">
        Security Program
      </Typography>
      <LoadingPanel async={async}>
        <div className={classes.centerContainer}>
          <div className={classes.columnContainer}>
            <div>
              <h3>Parameters</h3>
              <div style={{ display: "flex", flexDirection: "column" }}>
                {program.variables.map((v, i) => (
                  <TextField
                    type="number"
                    label={v.substr(0, 1).toUpperCase() + v.substr(1)}
                    id={i}
                    value={variables[v]}
                    onChange={evt => {
                      let obj = { ...variables };
                      obj[v] = evt.target.value;
                      setVariables(obj);
                    }}
                  />
                ))}
              </div>
              <Button disabled>Save as default</Button>
            </div>
            <div>
              <h3>Team</h3>
              <TeamResource infos={jobs} />
              Total:{" "}
              {Object.values(jobs)
                .reduce((prev, cur) => cur + prev, 0)
                .toFixed(2)}
            </div>
            <div>
              <h3>Cost</h3>
              <h4>{total.toFixed(2)}</h4>
            </div>
          </div>
        </div>
        {programItem ? (
          <div>
            <h3>{programItem.title}</h3>
            <ReactMarkdown source={programItem.description} />
          </div>
        ) : undefined}
        <ObjectList
          data={program.sections}
          title="Items"
          noPaper
          onItemClicked={n => {
            history.push(`/program/${n.id}`);
          }}
          headers={[
            {
              id: "Enable",
              getCellWidget: n => {
                return (
                  <Switch
                    checked={!disabled[n.id]}
                    onChange={() => {
                      const updates = { ...disabled };
                      updates[n.id] = !updates[n.id];
                      setDisabled(updates);
                    }}
                    name="checkedA"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                );
              }
            },
            {
              id: "category",
              getCellWidget: n => {
                return (
                  <div style={{ flexDirection: "row", display: "flex" }}>
                    {n.categories.map(c => {
                      let element = periodic.filter(i => i.acronym === c).pop();
                      let onClick = () => {
                        history.push("/security-table/" + c.toLowerCase());
                      };
                      if (c === "It") {
                        element = {
                          color: "#3F51B5"
                        };
                        onClick = undefined;
                      }
                      if (element) {
                        return (
                          <div
                            style={{
                              color: element.color,
                              width: 24,
                              height: 24,
                              borderWidth: 2,
                              borderColor: element.color,
                              borderStyle: "solid",
                              marginRight: 2,
                              padding: "3px",
                              overflow: "hidden",
                              cursor: onClick ? "pointer" : undefined
                            }}
                            onClick={onClick}
                          >
                            {c}
                          </div>
                        );
                      }
                      return undefined;
                    })}
                  </div>
                );
              },
              label: "Categories"
            },
            { id: "title", label: "Name" },
            { id: "team", label: "Team Formula", getCellWidget: n => <Formula formula={n.team} /> },
            { id: "cost", label: "Cost Formula", getCellWidget: n => <Formula formula={n.cost} /> },
            { id: "computedTeam", label: "Team", getCellWidget: n => <TeamResource infos={n.computedTeam} /> },
            { id: "computedCost", label: "Cost" }
          ]}
        />
      </LoadingPanel>
    </Paper>
  );
};

export default withStyles(styles)(SecurityProgram);
