import React from 'react';
import { Button, Dialog, DialogContent, DialogTitle, TextField, Box, DialogActions, FormLabel } from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper } from "@material-ui/core";

const renderTextItem = (label, value) => {
  if (value !== undefined && value !== null) {
    return (
      <TextField
        key={label}
        label={label}
        value={value}
        variant="outlined"
        style={{ margin: 5 }}
      //fullWidth
      />
    );
  }
  return null;
};

const renderError = (errors) => {
  if (!errors || errors.length === 0) return

  if (!Array.isArray(errors)) {
    errors = [errors]
  }
  return (<Box
    sx={{
      width: '79%',
      borderRadius: '5px',
      backgroundColor: '#f0f0f0',
      margin: "15px",
      padding: '10px',
      height: '50px',
      border: '1px solid #f44336',
      display: "flex",
      alignItems: "center"
    }}
  >
    <ErrorIcon color="error" />
    <p style={{
      padding: "10px"
    }}>{errors.map((error, index) => {
      return (<span>{error}<br /></span>)
    })}</p>

  </Box>)
};

const renderMap = (title, data) => {
  if (data) {
    console.log(data)
    return (
      <div>
        <FormLabel component={Paper} style={{ padding: '5px', background: "#f5f5f5" }}>
          {title}
        </FormLabel>
        <TableContainer component={Paper} >
          <Table>
            <TableBody>
              {Object.entries(data).map(([key, value]) => {
                return (<TableRow key={key}>
                  <TableCell>{key}</TableCell>
                  <TableCell>{value.status || value}</TableCell>
                </TableRow>)
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }
  return (<div></div>)
};

export const DomainIPDetails = ({ data }) => {
  if (!data) return;
  const {
    Instances,
    Dns,
    Value,
    Account,
    Region,
    Tags,
    IsPrivate,
    Provider,
    Scan,
    error
  } = data;

  return (
    <div>
      {renderError(error)}
      <div style={{ display: 'block' }}>
        <div style={{ padding: 7 }}>
          <Paper>
            {renderTextItem('Instances', (Instances || []).join(', '))}
            {renderTextItem('Dns', (Dns || []).join(', '))}
            {renderTextItem('Value', Value)}
            {renderTextItem('Account', Account)}
            {renderTextItem('Region', Region)}
            {renderTextItem('IsPrivate', (IsPrivate || "FALSE").toString())}
            {renderTextItem('Provider', Provider)}
          </Paper>
        </div>
        <div style={{ padding: 7 }}>
          <div style={{ display: 'flex' }}>
            <div style={{ flex: 1, paddingRight: 7 }}>
              {renderMap('Scan result', Scan)}
            </div>
            <div style={{ flex: 1, paddingLeft: 7 }}>
              {renderMap('Tags', Tags)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const DomainDnsDetails = ({ data }) => {
  if (!data) return;
  const {
    Name,
    Aliases,
    Value,
    Account,
    ResolvedIp,
    ResolvedProvider,
    Scan,
    errors,
  } = data;

  return (
    <div>
      {renderError(errors)}
      <div style={{ display: 'block' }}>
        <div style={{ padding: 7 }}>
          <Paper>
            {renderTextItem('Name', Name)}
            {renderTextItem('Aliases', (Aliases || []).join(', '))}
            {renderTextItem('Value', Value)}
            {renderTextItem('Account', Account)}
            {renderTextItem('Resolved IP', ResolvedIp)}
            {renderTextItem('Resolved Provider', ResolvedProvider)}
          </Paper>
        </div>
        <div style={{ padding: 7 }}>
          {renderMap('Scan result', Scan)}
        </div>
      </div>
    </div>
  );
};

const DomainDetailsDialog = ({ open, type, title, data, onClose }) => {
  let detailsComponent;
  if (type === "DNS") {
    detailsComponent = <DomainDnsDetails data={data} />;
  } else if (type === "IPS") {
    detailsComponent = <DomainIPDetails data={data} />;
  }
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {detailsComponent}
      </DialogContent>
      <DialogActions>
        <div>
          <Button onClick={onClose} color="primary">Cancel</Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default DomainDetailsDialog;
