import { Controller } from "./Controller";
import { CloudInventoryController } from "./CloudInventoryController";
import { CloudProjectsController } from "./CloudProjectsController";
import { ComputersController } from "./ComputersController";
import { DashboardController } from "./DashboardController";
import { EventsController } from "./EventsController";
import { HealthController } from "./HealthController";
import { MFAController } from "./MFAController";
import { ProxySSLController } from "./ProxySSLController";
import { RunbooksController } from "./RunbooksController";
import { ScreenCastingController } from "./ScreenCastingController";
import { TokensController } from "./TokensController";
import { UserController } from "./UserController";
import { ComplianceController } from "./ComplianceController";
import { WorkSpacesController } from "./WorkSpacesController"


class ControllerInitializer {
  static initialize() {
    new CloudInventoryController();
    new CloudProjectsController();
    new ComputersController();
    new DashboardController();
    new EventsController();
    new HealthController();
    new MFAController();
    new ProxySSLController();
    new RunbooksController();
    new ScreenCastingController();
    new TokensController();
    new UserController();
    new ComplianceController();
    new WorkSpacesController();
  }
}

export { ControllerInitializer, Controller };
