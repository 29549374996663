import React from "react";
import { useSelector } from "react-redux";
import { Controller } from "../controllers";
import "./ReactUserSelector.css";
import { Avatar, Chip } from "@material-ui/core";
import Select from "react-select";

const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between"
};

const groupBadgeStyles = {
  backgroundColor: "#EBECF0",
  borderRadius: "2em",
  color: "#172B4D",
  display: "inline-block",
  fontSize: 12,
  fontWeight: "normal",
  lineHeight: "1",
  minWidth: 1,
  padding: "0.16666666666667em 0.5em",
  textAlign: "center"
};

const formatGroupLabel = data => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);

const ReactUserSelector = ({ label, value, id, onUpdate, singleSelection, excludeUsers, excludeGroups, excludeSystemUsers }) => {

  const users = useSelector(state => state.users.users);
  const groups = useSelector(state => state.users.groups);
  const systemusers = useSelector(state => state.users.systemusers);

  const getOptionLabel = (value, i) => {
    return <Chip key={i} avatar={getAvatar(value.gravatar)} label={value.displayName || value.name} />;
  };
  const getOptionValue = opt => {
    return `${opt.type}_${opt.name}`;
  };

  const getAvatar = value => {
    if (value && value !== undefined) {
      let src = `//www.gravatar.com/avatar/${value}`;
      return <Avatar src={src} />;
    }
  };
  const onChange = value => {
    value = value || [];
    if (!onUpdate) {
      return;
    }
    if (singleSelection) {
      onUpdate({
        name: value.name,
        type: value.type
      });
    } else {
      onUpdate(
        value.map(item => ({
          name: item.name,
          type: item.type
        }))
      );
    }
  };

  const getReplacementValue = val => Controller.get("users").getUserInfo(val);

  const groupedOptions = [];
  if (!excludeUsers) {
    groupedOptions.push({
      label: "Users",
      options: users || []
    });
  }
  if (!excludeGroups) {
    groupedOptions.push({
      label: "Groups",
      options: groups || []
    });
  }
  if (!excludeSystemUsers) {
    groupedOptions.push({
      label: "System users",
      options: systemusers || []
    });
  }
  if (!singleSelection) {
    value = (value || []).map(getReplacementValue);
  } else {
    value = getReplacementValue(value);
  }

  return (
    <Select
      value={value}
      isMulti={!singleSelection}
      label={label}
      options={groupedOptions}
      formatGroupLabel={formatGroupLabel}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      className="basic-multi-select"
      classNamePrefix="select"
      isSearchable={true}
      onChange={onChange}
      id={id}
    />
  );
}

export default (ReactUserSelector);
