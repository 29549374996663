import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Controller } from "../controllers";
import { Card } from "semantic-ui-react";
import ReactSelect from "react-select";
import ReactUserSelector from "../common/ReactUserSelector";
import PhoneInput from "react-phone-number-input";
import { DateInput } from "semantic-ui-calendar-react";
import { withStyles } from "@material-ui/core/styles";
import "react-phone-number-input/style.css";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from "@material-ui/core";

const styles = theme => ({
  paper: {
    width: "80%"
  },
  dialogContent: {
    padding: 10
  },
  progress: {
    margin: 20
  }
});

function OnboardingBox({ onClose, onboarding, locations, onChange, onSubmit, data, valid, waiting }) {
  let locationsList = [];
  for (let l in locations) {
    locationsList.push({ label: locations[l][0], value: l });
  }
  locationsList.sort((a, b) => (a.value < b.value));
  return (
    <Dialog disableBackdropClick disableEscapeKeyDown aria-labelledby="onboarding-title" open={onboarding}>
      <DialogTitle id="onboarding-title">Onboarding</DialogTitle>
      <DialogContent>
        <br />
        <Card key="onboarding">
          <Card.Content>
            <TextField label="Forced Login" placeholder="Automatic" onChange={onChange("forcedLogin")} />
            <TextField label="First name" required onChange={onChange("firstname")} />
            <TextField label="Last name" required onChange={onChange("lastname")} />
            <TextField label="Personal email" required onChange={onChange("homeEmail")} />
            <br />
            <br />
            Personal phone:
            <PhoneInput required placeholder="Personal phone" value={data.phone} onChange={onChange("phone")} />
            <br />
            Location:
            <ReactSelect
              label="Location"
              required
              value={locationsList[data.location]}
              options={locationsList}
              onChange={onChange("location")}
            />
            <br />
            Team:
            <ReactUserSelector
              label="Team name"
              required
              singleSelection
              onUpdate={onChange("team")}
              id="onboardee-team"
              value={data.team}
              excludeSystemUsers
              excludeUsers
            />
            <br />
            Reports to:
            <ReactUserSelector
              label="Reports to"
              required
              singleSelection
              onUpdate={onChange("supervisor")}
              id="onboardee-supervisor"
              value={data.supervisor}
              excludeSystemUsers
              excludeGroups
            />
            <br />
            Starting date:
            <DateInput
              required
              name="dateTimeOnboarding"
              value={data.targetDate}
              placeholder="Effective starting date"
              onChange={onChange("targetDate")}
              animation="off"
              startMode="day"
              closeOnMouseLeave={false}
              minDate={new Date()}
              initialDate={new Date()}
              dateFormat="YYYY-MM-DD"
            />
            <TextField label="Github Login" onChange={onChange("githubLogin")} />
          </Card.Content>
        </Card>
      </DialogContent>
      <DialogActions>
        <div>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          {valid && (
            <Button onClick={onSubmit} color="primary">
              Validate now
            </Button>
          )}
        </div>
        {waiting && (
          <div>
            <div align="center">
              <DialogContentText>Please wait...</DialogContentText>
              <CircularProgress bottom={0} left={0} className={styles.progress} />
            </div>
            <Button onClick={onClose} color="primary">
              Cancel
            </Button>
          </div>
        )}
      </DialogActions>
    </Dialog>
  );
}

const UsersOnboarding = () => {

  const [data, setData] = useState({ targetDate: new Date().toISOString().split("T")[0] });
  const [valid, setValid] = useState(false);
  const [waiting] = useState(false);
  const onboarding = useSelector(state => state.users.onboarding);
  const locations = useSelector(state => state.users.locations);

  useEffect(() => {
    setValid(
      data.firstname && data.firstname !== "" &&
      data.lastname && data.lastname !== "" &&
      data.team && data.team !== "" &&
      data.location && data.location !== "" &&
      data.supervisor && data.supervisor !== "" &&
      data.phone && data.phone !== "" &&
      data.homeEmail && data.homeEmail !== ""
    );
  }, [data]);

  function onClose() {
    Controller.get("users").hideOnboarding();
  }
  function onSubmit() {
    Controller.get("users").onboardEmployee(data);
    onClose();
  }
  function onChange(field) {
    return (event, { value: v } = {}) => {
      if (event === undefined && !v) return;
      let value = v;
      if (!value && event) {
        if (event.target) value = event.target.value;
        else value = event;
      }
      let getVal = v => v.name || v.value || v;
      setData((prevState) => ({
        ...prevState,
        [field]: getVal(value)
      }));
    };
  }
  return (
    <OnboardingBox
      classes={{
        paper: styles.paper
      }}
      id="ringtone-menu"
      keepMounted
      onSubmit={onSubmit}
      onChange={onChange}
      onClose={onClose}
      onboarding={onboarding}
      locations={locations}
      data={data}
      valid={valid}
      waiting={waiting}
    />
  );
}

export default withStyles(styles)(UsersOnboarding);
