import { Paper, Tab, Tabs } from "@material-ui/core";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import RunbooksComponent from "./RunbooksComponent";
import TasksRunning from "./TasksRunning";
import TasksScheduled from "./TasksScheduled";
//import TasksRun from "./TasksRun";

const Tasks = () => {

  const [tab, setTab] = useState("running");
  const handleChange = (event, tab) => {
    setTab(tab);
  };

  const running = useSelector(state => state.runbooks.running);
  const scheduled = useSelector(state => state.runbooks.scheduled);
  const runbooks = useSelector(state => state.runbooks.list);
  const manualTaskState = useSelector(state => state.runbooks.manualTaskState);

  return (
    <Paper style={{ marginTop: "20px" }}>
      <Tabs value={tab} onChange={handleChange} indicatorColor="primary" textColor="primary" centered>
        <Tab label={`Runbooks (${runbooks.length})`} value="runbooks" />
        <Tab label={`Scheduled (${scheduled.length})`} value="scheduled" />
        <Tab label={`Running (${running.length})`} value="running" />
        {/* <Tab label="Run" value="run" disabled /> */}
      </Tabs>
      {tab === "runbooks" && <RunbooksComponent />}
      {tab === "scheduled" && <TasksScheduled manualTaskState ={manualTaskState}/>}
      {tab === "running" && <TasksRunning />}
      {/*tab === "run" && <TasksRun />} */}
    </Paper>
  );
}

export default Tasks;
