import React from "react";
import ESStatusPanel from "./ESStatusPanel";
import Health from "./Health";
import HealthTeam from "./HealthTeam";
import CenteredTabs from "../common/CenteredTabs";

export default function HealthTabs() {
  return (
    <CenteredTabs
      tabs={[
        {
          label: "Overall",
          panel: <Health />
        },
        {
          label: "ElasticSearch",
          panel: <ESStatusPanel />
        },
        {
          label: "Okta",
          panel: <HealthTeam />
        }
      ]}
      defaultTab={1}
    />
  );
}
