import {
  AccountBalance,
  AssignmentInd,
  Cast,
  Cloud,
  DeveloperBoard,
  DeviceHub,
  Dns,
  Extension,
  Face,
  LibraryBooks,
  NetworkCheck,
  LocalHospital,
  Help,
  Group,
  Business,
  Security,
  Storage,
  TableChart,
  VerifiedUser,
  ViewModule,
  Assessment,
  AttachMoney,
  AddToQueue,
  CheckCircle
} from "@material-ui/icons";
import { toast } from "react-toastify";
import { Controller } from "./Controller";
import * as crypto from "crypto";

class UserController extends Controller {
  history: any;
  _menu: any;
  constructor() {
    super("users", {
      onboarding: false
    });
    this.registerRoute({ path: "/users/:uuid", strict: false, exact: true }, this._fetchUserRoute);
    this.registerRoute({ path: "/groups/:uuid", strict: false, exact: true }, this._fetchUserRoute);
  }

  fetch(event = "LOGIN") {
    this.asyncAction(event, async () => {
      let me = await this.ajax("/me");
      me = this.decryptData(me);
      return { me: me };
    });
  }

  decryptData(data: any) {
    let dataArr = data.encryptedData.split('|');
    if (dataArr.length >= 3) {
      let encryptedText = Buffer.from(dataArr[0], 'hex');
      let decipher = crypto.createDecipheriv('aes-256-cbc', Buffer.from(dataArr[1], 'hex'), Buffer.from(dataArr[2], 'hex'));
      let decrypted = decipher.update(encryptedText);
      decrypted = Buffer.concat([decrypted, decipher.final()]);
      return JSON.parse(decrypted.toString());
    } else {
      return null;
    }
  }

  redirectToOkta() {
    // @ts-ignore
    window.location = Controller.endpoint + "/auth/okta";
  }

  logout() {
    // @ts-ignore
    window.location = Controller.endpoint + "/auth/logout";
  }

  onLOGIN_FAILED() {
    setTimeout(this.redirectToOkta.bind(this), 3000);
    return { redirectToOkta: true };
  }

  onROUTE_CHANGED(state, action) {
    if (action.location.pathname.startsWith("/helpdesk")) {
      // @ts-ignore
      window.location = "https://jira.nuxeo.com/servicedesk/customer/portal/2";
    }
    return { ...state };
  }

  init(): boolean {
    super.init();
    // Wait to retrieve currentUser and users list
    return false;
  }

  afterLOGIN_SUCCESS() {
    this.fetchUsersAndGroups();
  }

  sync(callback = undefined) {
    this.asyncAction(
      "SYNC_OKTA",
      async () => {
        await this.waitInit();
        await this.ajax(`/okta/sync`, "PUT")
          .catch(err => {
            if (err.code === 403) {
              toast.error("Access denied. You do not have the necessary privileges to proceed. Please contact Security-Admin.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 6000
              });
            }
          });
        return await this.ajax("/users");
      },
      () => {
        if (callback) {
          callback();
        }
      }
    );
  }

  getMe() {
    return this.getLocalState().me;
  }

  hasPermission(right) {
    let me = this.getLocalState().me || { groups: [] };
    me.permissions = me.permissions || {};
    return me.permissions[right];
  }

  getUserInfo(user) {
    if (!user) {
      return user;
    }
    if (typeof user === "string") {
      user = {
        type: "user",
        name: user
      };
    }
    let info;
    if (user.type === "user") {
      info = this.getLocalState().users;
    } else if (user.type === "group") {
      info = this.getLocalState().groups;
    } else if (user.type === "systemuser") {
      info = this.getLocalState().systemusers;
    }
    info = info || [];
    for (let i in info) {
      if (info[i].name === user.name) {
        info[i].permissions = info[i].permissions || {};
        return info[i];
      }
    }
    user.permissions = user.permissions || {};
    return user;
  }

  // Returns TRUE if user of his group is in the listUser
  isGrantedByThisList(list) {
    let {
      me: { login, groups }
    } = this.getLocalState();
    for (let k in list) {
      let { name, type } = list[k];
      if ((type === "user" && name === login) || (type === "group" && groups.indexOf(name) > -1)) {
        return true;
      }
    }
    return false;
  }

  getPermissions() {
    let permissions = [
      {
        route: "/cloudprojects",
        title: "Projects",
        submenu: "Cloud",
        description: "Allow you to distribute your SSH keys to your servers and get informations on your environment",
        icon: Cloud
      },
      {
        route: "/amazon-machine-images",
        title: "AWS AMIs",
        submenu: "Cloud",
        description: "Retrieve latest AMIs informations built by your security team",
        icon: ViewModule,
        right: "cloudInventory"
      },
      {
        route: "/cloud-inventory",
        title: "Inventory",
        submenu: "Cloud",
        description: "Visualize all Nuxeo AWS resources",
        icon: Storage,
        right: "cloudInventory"
      },
      {
        route: "/customers",
        title: "Customers",
        submenu: "Cloud",
        description: "Display cloud customer view",
        icon: AssignmentInd,
        right: "customers"
      },
      {
        route: "/domains",
        title: "Domains and Public IPs",
        submenu: "Security",
        description: "Display our domains and public IP",
        icon: Dns,
        right: "domains"
      },
      {
        route: "/proxies-ssl",
        title: "Proxies SSL",
        submenu: "Cloud",
        description: "Manage proxy policies",
        icon: DeviceHub
      },
      {
        route: "/security-automation",
        title: "Automation",
        description: "Schedule or Run, Manage, Monitor every automation tasks",
        submenu: "Security",
        icon: Security,
        right: "securityAutomation"
      },
      {
        route: "/security-table",
        title: "Security Table",
        submenu: "Security",
        description: "Nuxeo Security Team tasks are diversed, understand them using our Security Periodic Table",
        icon: TableChart,
        right: "securityTable"
      },
      {
        route: "/tokens",
        title: "Approvals",
        submenu: "Security",
        description: "Tokens and security rules",
        icon: VerifiedUser,
        right: "tokens"
      },
      {
        route: "/workspaces",
        title: "Workspace Approval",
        submenu: "Security",
        description: "Submit a request to provision AWS WorkSpaces through the approval process.",
        icon: CheckCircle,
        right: "workspaces"
      },
      {
        route: "/screencasts",
        title: "Screencasts",
        submenu: "Offices",
        description: "Manage screens cast in Nuxeo offices",
        icon: Cast,
        right: "screencast"
      },
      {
        route: "/health",
        title: "Automation Health",
        submenu: "Security",
        description: "Metrics and status of critical security systems",
        icon: LocalHospital,
        right: "securityAutomation"
      },
      {
        route: "/scans",
        title: "Network Scanners",
        submenu: "Security",
        description: "IPs, VPCs Scans",
        icon: NetworkCheck,
        right: "securityAutomation"
      },
      {
        route: "/docs",
        title: "Documents",
        submenu: "Offices",
        description: "Find policies, procedures and other documents generated by your security team",
        icon: LibraryBooks
      },
      {
        route: "/costs",
        title: "Costs",
        submenu: "Cloud",
        description: "Costs and money related intelligence and automation",
        icon: AccountBalance,
        right: "costs"
      },
      {
        route: "/users",
        title: "Management",
        submenu: "Users",
        description: "Users and Groups permissions",
        icon: Face,
        right: "users"
      },
      {
        route: "/swagger",
        title: "API Playground",
        description: "Play with API",
        submenu: "Security",
        icon: Extension,
        right: "swagger"
      },
      {
        route: "/tests",
        title: "Tests",
        submenu: "Security",
        description: "Display current state of unit test / webdriver test",
        icon: DeveloperBoard,
        right: "tests"
      },
      {
        route: "/compliance-reports",
        title: "Compliance Reports",
        description: "HiTrust, PCI/DSS, SOC2 reports status",
        submenu: "Security",
        icon: Assessment,
        right: "complianceReports"
      },
      {
        route: "/program",
        title: "Security Program",
        description: "Budget information and security program item description",
        submenu: "Security",
        icon: AttachMoney,
        right: "complianceReports"
      },
      {
        route: "/typedoc",
        title: "TypeDoc",
        submenu: "Security",
        description: "Display typedoc generated document for various modules of typescript",
        icon: AddToQueue,
        right: "typedoc"
      },
    ];
    return permissions.filter(section => {
      return section.right ? this.hasPermission(section.right) : true;
    });
  }

  getMenu() {
    if (this._menu) {
      return this._menu;
    }
    this._menu = {
      Cloud: {
        items: [],
        icon: Cloud,
        label: "Cloud"
      },
      Offices: {
        items: [],
        icon: Business,
        label: "Offices"
      },
      Security: {
        items: [],
        icon: Security,
        label: "Security"
      },
      Users: {
        items: [],
        icon: Group,
        label: "Users"
      }
    };
    let permissions = this.getPermissions();
    permissions.forEach(perm => {
      if (!this._menu[perm.submenu]) {
        this._menu[perm.submenu] = {
          items: [],
          label: perm.submenu,
          icon: Help
        };
      }
      this._menu[perm.submenu].items.push(perm);
    });
    for (let i in this._menu) {
      if (this._menu[i].items.length === 0) {
        delete this._menu[i];
      }
    }
    return this._menu;
  }

  _fetchUserRoute(params) {
    this.fetchUserOrGroup(params.uuid);
  }

  getUserCloudTrailDescription(evt) {
    let { eventName, accountName, awsRegion, sourceIPAddress, userAgent } = evt;
    return `${eventName} on ${accountName} in ${awsRegion} from ${sourceIPAddress} with ${userAgent}`;
  }

  /*
   * Retrieve every details data for this user
   * @returns {Promise<any>}
   */
  fetchUserOrGroup(id) {
    this.asyncAction("USER_PRESENCE_STATS", async () => {
      let heatmap: any = await this.ajax(`/heatmap/${id}`);
      return { heatmap };
    });
    this.asyncAction("GET_USER", async () => {
      await this.waitInit();
      let current: any = await this.ajax(`/users/${id}`)
        .catch(err => {
          if (err.code === 403) {
            toast.error("Access denied. You do not have the necessary privileges to proceed. Please contact Security-Admin.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 6000
            });
          }
        });
      return { current };
    });
  }

  updateUser(updates) {
    this.asyncAction("UPDATE_USER", async () => {
      let user_uuid = this.getLocalState().current.uuid;
      await this.ajax(`/users/${user_uuid}`, "PATCH", updates).then(response => {
        Controller.dispatch({ type: "UPDATE_USER", result: response, user_uuid: user_uuid })
      }).catch(err => {
        Controller.dispatch({ type: "UPDATE_USER", error: err, user_uuid: user_uuid })
      })
    });
  }

  onUPDATE_USER(state, data) {
    let result = data.result;
    if (data.error) {
      let toastMessage = (data.error.code === 403) ? "Access denied. You do not have the necessary privileges to proceed. Please contact Security-Admin." : "Unknown error occured."
      toast.error(`${toastMessage}`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 6000
      });
    }
    if (result) {
      toast.success("Data saved successfully.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000
      });
    }

    return { ...state, result }
  }


  fetchUsersAndGroups() {
    this.asyncAction(
      "USERS_LIST",
      async () => {
        return await this.ajax("/users");
      },
      this.setInitialized.bind(this)
    );
  }

  create(user) {
    this.asyncAction("CREATE_SYSUSER", async () => {
      let result: any = await this.ajax("/users", "POST", user)
        .catch(err => {
          if (err.code === 403) {
            toast.error("Access denied. You do not have the necessary privileges to proceed. Please contact Security-Admin.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 6000
            });
          }
        });
      let users = this.getLocalState().users.slice(0);
      users.users.push(result);
      this.history.push("/users/");
      return { current: result, users };
    });
  }

  // Displaying the onboarding control box
  showOnboarding() {
    Controller.dispatch({ type: "SHOW_ONBOARDING_DIALOG" });
  }
  onSHOW_ONBOARDING_DIALOG(state) {
    return { ...state, onboarding: true };
  }
  hideOnboarding() {
    Controller.dispatch({ type: "HIDE_ONBOARDING_DIALOG" });
  }
  onHIDE_ONBOARDING_DIALOG(state) {
    return { ...state, onboarding: false };
  }

  /*
   * This will offboard an employee
   * @param {Object} user to offboard
   * @param {Object} params to transmit as POST body (for example type of confirmation, value of TOTP provided, ...)
   **/
  offboardEmployee(user, params) {
    let action = "offboardEmployee";
    params = { ...params, login: user.login };
    this.asyncAction("OFFBOARD_USER_GO", async () => {
      return await this.ajax(
        `/users/${user.uuid}/offboardEmployee`,
        "DELETE",
        params,
        { action } // action is important to be triggered in case of async MFA
      ).then(r => {
        if (r && r.offboarding) {
          // MFA not expired, immediate execution
          Controller.dispatch({ type: action });
        }
      })
        .catch(err => {
          if (err.code === 403) {
            toast.error("Access denied. You do not have the necessary privileges to proceed. Please contact Security-Admin.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 6000
            });
          }
        });
    })
  }

  onoffboardEmployee(state, action) {
    toast.success(`Initiating now action : OFFBOARDING USER`, {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 10000
    });

    return {
      ...state,
      result: action.result
    };
  }

  /*
   * This will onboard an employee
   * @param {Object} user to onboard
   * @param {Object} params to transmit as POST body (for example type of confirmation, value of TOTP provided, ...)
   **/
  onboardEmployee(params) {
    let action = "onboardEmployee";
    this.asyncAction("OFFBOARD_USER_GO", async () => {
      return await this.ajax(
        `/users/onboardEmployee`,
        "POST",
        params,
        { action } // action is important to be triggered in case of async MFA
      ).then(r => {
        if (r && r.onboarding) {
          // MFA not expired, immediate execution
          Controller.dispatch({ type: action });
        }
      }).catch(err => {
        if (err.code === 403) {
          toast.error("Access denied. You do not have the necessary privileges to proceed. Please contact Security-Admin.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 6000
          });
        }
      });
    })
  }

  ononboardEmployee(state, action) {
    toast.success(`Initiating now action : ONBOARDING USER`, {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 10000
    });

    return {
      ...state,
      result: action.result
    };
  }
}

export { UserController };
