import { Controller } from "./Controller";

class EventsController extends Controller {
  history: any;
  location: any;
  constructor() {
    super("events", { events: [], current: {} });
    this.registerRoute({ path: "/event/:id", strict: false, exact: true }, this._fetchProjectRoute);
  }

  async handleSyncAction(action) {
    if (action.type === "UPDATE") {
      await this.ajax("/tasks/" + action.id, "PUT", action.params);
      return;
    }
  }

  getURL() {
    return Controller.endpoint + "/es/";
  }

  init() {
    super.init();
    return false;
  }

  create(project) {
    this.asyncAction("CREATE_EVENT", async (dispatch, getState) => {
      let result: any = await this.ajax("/event", "POST", project);
      // Should reroute clean
      let events = this.getLocalState().events.slice(0);
      events.push(result);
      this.history.push("/event/" + project.uuid);
      return { current: result, events };
    });
  }

  update(uuid, updates, callback) {
    this.asyncAction(
      "UPDATE_EVENT",
      async (dispatch, getState) => {
        let project = await this.ajax("/event/" + uuid + "/update", "PUT", updates);
        let events = this.getLocalState().events.map(proj => {
          if (proj.uuid === uuid) {
            return project;
          } else {
            return proj;
          }
        });
        return { current: project, events };
      },
      () => {
        if (callback) {
          callback();
        }
      }
    );
  }

  get(uuid) {
    this.asyncAction("GET_EVENT", async (dispatch, getState) => {
      await Controller.get("users").waitInit();
      let event: any = await this.ajax("/event/" + uuid);
      return { current: event };
    });
  }

  onROUTE_CHANGED(state, action) {
    this.location = action.location;
    if (action.history) {
      this.history = action.history;
    }
    return state;
  }

  _fetchProjectRoute(params) {
    this.get(params.id);
  }

  afterLOGIN_SUCCESS() {
    this.asyncAction(
      "GET_DEFAULT_SERVICES",
      async (dispatch, getState) => {
        let services: any = await this.ajax("/dashboard/server_services");
        return { defaultServices: services };
      },
      () => {
        this.setInitialized();
      }
    );
    /*
    this.asyncAction("SYNC_EVENTS", async (dispatch, getState) => {
      let events: any = await this.ajax("/events");
      await this.waitInit();
      return { events };
    });
    */
  }

  getDefaultConfig(service, type, override) {
    let defaultServices = this.getLocalState().defaultServices;
    let defaultService = defaultServices.services[service] || {};
    let defaultEnv = {};
    if (defaultServices.envs[type]) {
      defaultEnv = defaultServices.envs[type].services[service];
    }
    let result = {
      ...defaultService,
      ...defaultEnv
    };
    result.strict = result.strict || {};
    if (this.hasPermission("eventOverride") && override) {
      result.strict = {};
    }
    return result;
  }
}
export { EventsController };
