import { withStyles } from "@material-ui/core/styles";
import React from "react";
import { connect } from "react-redux";
import Breadcrumb from "../common/Breadcrumb";
import { blue } from "@material-ui/core/colors";
import ReactJson from "react-json-view";
import ToggleButton from "@material-ui/lab/ToggleButton";
import { Header } from "semantic-ui-react";
import LoadingPanel from "../common/LoadingPanel";
import ErrorIcon from "@material-ui/icons/Error";

import { Paper, TextField, Box } from "@material-ui/core";

const styles = theme => ({
  root: {
    padding: 20,
    marginTop: 20
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  },
  description: {
    width: "100%"
  },
  addEnv: {
    margin: 20,
    width: "300px"
  },
  colorSwitchBase: {
    "&$colorChecked": {
      color: blue[500],
      "& + $colorBar": {
        backgroundColor: blue[500]
      }
    }
  },
  colorChecked: {},
  colorBar: {},
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  }
});

class CloudProjectServerDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rawJSON: false
    };
  }

  renderTextItem(lable, value) {
    if (value) {
      return (<TextField label={lable} value={value} variant="outlined" style={{ margin: 15 }} />)
    }
    return;

  }

  renderError(error) {
    if (error) {
      return (<Box
        sx={{
          width: '79%',
          borderRadius: '5px', 
          backgroundColor: '#f0f0f0', 
          margin: "15px",
          padding: '10px',
          height: '50px', 
          border: '1px solid #f44336', 
          display: "flex",
          alignItems: "center"
        }}
      >
        <ErrorIcon color="error" />
         <p style={{
          padding: "10px"
        }}>{error}</p>

      </Box>)
    }
    return;
  }

  renderComponent(server) {
    return (
      <div>
        {this.renderError(server.error)}
        <div style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "baseline"
        }}>
          {this.renderTextItem("Hostname", server.hostname)}
          {this.renderTextItem("ami-id", server["ami-id"])}
          {this.renderTextItem("Instance-id", server["instance-id"])}
          {this.renderTextItem("Instance-type", server["instance-type"])}
          {this.renderTextItem("Availability-zone", server["availability-zone"])}
          {this.renderTextItem("Environment", server.environment)}
        </div>
        <div style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "baseline"
        }}>
          {this.renderTextItem("System", server.system)}
          {this.renderTextItem("Machine", server.machine)}
          {this.renderTextItem("Platform", server.platform)}
          {this.renderTextItem("Processor", server.processor)}
          {this.renderTextItem("Mac", server.mac)}
          {this.renderTextItem("Public Ip", server.publicIp)}
        </div>
        <div style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "baseline"
        }}>
          {this.renderTextItem("Python version", server.python_version)}
          {this.renderTextItem("Shim version", server.shim_version)}
          {this.renderTextItem("Release", server.release)}
          {this.renderTextItem("Version", server.version)}
          {this.renderTextItem("Created", server._creationDate)}
          {this.renderTextItem("LastSeen", server.lastSeen)}
        </div>

        <Box
          sx={{
            width: '79%'
          }}
        >
          <TextField fullWidth
            label="Public key"
            value={server.server_rsa_public_key}
            variant="outlined"
            multiline
            style={{ margin: 15 }} />
        </Box>
      </div>
    )
  }

  onRawJSON = () => {
    this.setState(prevState => {
      return { rawJSON: !prevState.rawJSON };
    });
  };
  render() {
    const { classes, async, server } = this.props;
    let { rawJSON } = this.state;
    let title = `${server.hostname}`;
    let component;
    if (!rawJSON) {
      try {
        component = this.renderComponent(server)
      } catch (e) {
        component = (<div>e</div>)
      }
    }
    if ((!component || rawJSON) && server) {
      component = <ReactJson src={server} />;
      rawJSON = true;
    }
    console.log(`${server}`)
    return (
      <div>
        <Paper className={classes.root}>
          <Header as="h4">Server Details</Header>
          <LoadingPanel async={async}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Breadcrumb items={[{ title: `${server.project}`, path: `/cloudprojects/${server.project}` }, { title }]} />
              <ToggleButton value={false} variant="outlined" selected={rawJSON} onClick={this.onRawJSON}>
                Raw JSON
              </ToggleButton>
            </div>
            <div className={classes.container}>{component}</div>
          </LoadingPanel>
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles)(
  connect((state, ownProps) => {
    let currentServers = state.cloudprojects.currentServers || [];
    return {
      async: state.cloudprojects._async.GET_CLOUDPROJECT,
      server: currentServers.find(s => s.uuid === ownProps.match.params.serverID) || {}
    };
  })(CloudProjectServerDetails)
);
