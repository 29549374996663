import React, { useState } from "react";
import { Controller } from "../controllers";
import { useSelector } from "react-redux";
import LoadingPanel from "./LoadingPanel";
import { Dropdown } from "semantic-ui-react";
import ReactMarkdown from "react-markdown";
import dateFormat from "dateformat";
import { Button, Divider, Paper } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import DateIcon from "@material-ui/icons/DateRange";
import DeployIcon from "@material-ui/icons/Launch";
import NewIcon from "@material-ui/icons/SpeakerNotes";

const styles = {
  root: {
    padding: 20,
    marginTop: 20
  },
  flexContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: 10
  },
  newIcon: {
    marginRight: "20px"
  },
  headerText: {
    margin: "10px",
    display: "flex"
  },
  markDownContent: {
    padding: "30px"
  },
  addButton: {
    position: "relative",
    flex: 1,
    flexDirection: "row-reverse",
    display: "flex"
  },
  headerIcon: {
    marginRight: "10px"
  }
};

const ReleasesMD = ({ classes, history }) => {
  const [project, setProject] = useState("dashboard");
  const async = useSelector(state => state.dashboard._async.SYNC_CHANGELOG);
  const releases = useSelector(state => state.dashboard.currentChangeLog || []);
  const changelogs = useSelector(state => state.dashboard.changelogs || {});
  const projectSelect = project => {
    setProject(project);
    Controller.get("dashboard").loadChangeLog(project);
  };
  var currentVersion = "";
  if (releases && releases.length) {
    currentVersion = releases[0].tag;
  }
  let deployButton;
  if (Controller.get("users").hasPermission("deploy") && window.location.host!=="soc.nuxeo.com" &&  ["dashboard", "api", "tasks"].indexOf(project) >= 0) {
    deployButton = (
      <div className={classes.addButton}>
        <Button
          onClick={() => {
            history.push(`/deploy-beta/${project}`);
          }}
          variant="outlined"
          color="primary"
        >
          <DeployIcon className={classes.deployIcon} />
            DEPLOY BETA TO PRODUCTION
          </Button>
      </div>
    );
  }
  return (
    <div>
      RELEASES NOTES
      <br />
        Project:{" "}
      <Dropdown
        className="amisDropdown"
        placeholder="Project Selector"
        options={Object.keys(changelogs).map(changelog => {
          return {
            text: changelog,
            value: changelog
          };
        })}
        value={project}
        onChange={(_, { value }) => projectSelect(value)}
      />
      <br />
        Current Version: {currentVersion}
      <LoadingPanel async={async}>
        {deployButton}
        {releases
          .sort((a, b) => (a.date < b.date ? 1 : -1))
          .map((release, i) => {
            return (
              <Paper key={i} className={classes.root}>
                <div className={classes.flexContainer}>
                  <div>
                    <div className={classes.headerText}>
                      <DateIcon className={classes.headerIcon} />{" "}
                      {dateFormat(new Date(release.date), "yyyy-mm-dd HH:MM Z")}
                    </div>
                    <div className={classes.headerText}>
                      <NewIcon className={classes.headerIcon} /> {release.tag}
                    </div>
                  </div>
                </div>
                <Divider />
                <ReactMarkdown className={classes.markDownContent} source={release.md} />
              </Paper>
            );
          })}
      </LoadingPanel>
    </div>
  );
}

export default withStyles(styles)(ReleasesMD);
