import { Button, Paper, Tab, Tabs, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { Controller } from "../controllers";
import LoadingPanel from "../common/LoadingPanel";
import TokensDetails from "./TokensDetails";
import DialogBox from "./TokensDialogBox";
import SgRule from "./TokensSgRule";
import isEqual from 'lodash/isEqual';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import * as json2csv from 'json2csv'
import { saveAs } from "file-saver";

const styles = theme => ({
  root: {
    padding: 20,
    marginTop: 20
  },
  addButton: {
    position: "relative",
    flex: 1,
    flexDirection: "row-reverse",
    display: "flex"
  }
});

const initialState = {
  TokenType: "SG_RULE",
  Type: "INGRESS",
  IpProtocol: "TCP",
  FirewallRequest: "PERMANENT"
};
 
const Tokens = ({ classes, history, location }) => {

  const [data, setData] = useState(initialState);
  const [valid, setValid] = useState(false);
  const [tabValue, setTabValue] = useState("pending");
  const [initialData, setInitialData] = useState(initialState);
  const async = useSelector(state => state.tokens._async.SYNC_TOKENS || {});
  const controller = useSelector(state => state.tokens);
  const list = useSelector(state => state.tokens.list);

  function handleChangeTab(event, newValue) {
    setTabValue(newValue);
  }
  function onNew() {
    Controller.get("tokens").showCreateNewTokenDialog();
  }
  function onDelete() {
    Controller.get("tokens").showDeleteTokenDialog();
  }
  function onReview(action, opt) {
    if (action === "delete") {
      Controller.get("tokens").deleteToken(opt);
    } else {
      Controller.get("tokens").showKeyPasswordDialog(action, opt);
    }
  }
  function onEdit(token) {
    setInitialData(token);
    setData({ ...data, ...token });
    Controller.get("tokens").showEditTokenDialog();
  }
  function onClose() {
    setData(initialState);
    setValid(false);
    setInitialData(initialState);
    Controller.get("tokens").hideKeyPasswordDialog();
    Controller.get("tokens").hideDeleteTokenDialog();
    Controller.get("tokens").hideCreateNewTokenDialog();
    Controller.get("tokens").hideEditTokenDialog();
  }
  function onSubmit(RequestType) {
    Controller.get("tokens").submitToken({ ...data, RequestType });
    onClose();
  }
  function onSubmitApprove(tokenData) {
    Controller.get("tokens").approveToken({ ...tokenData, Password: data.Password });
    onClose();
  }
  function onSubmitRevoke(tokenData) {
    Controller.get("tokens").revokeToken({ ...tokenData, Password: data.Password });
    onClose();
  }
  function onChange(field) {
    return (event, { value: v } = {}) => {
      if (event === undefined && !v) return;
      let value = v;
      if (!value && event) {
        if (event.target) value = event.target.value;
        else value = event;
      }
      let getVal = v => v.name || v.value || v;
      let newState = { ...data, [field]: getVal(value) }
      setData(newState);
      setValid(
        (newState.Password && newState.Password !== "") ||
        (newState.TokenId && newState.TokenId !== "") ||
        (
          !isEqual(initialData,newState) &&
          (newState.TokenType && newState.TokenType !== "") &&
          (newState.Type && newState.Type !== "") &&
          (newState.GroupName && newState.GroupName !== "") &&
          (newState.IpProtocol && newState.IpProtocol !== "") &&
          (newState.FirewallRequest && newState.FirewallRequest !== "") &&
          (newState.Target && newState.Target !== "") &&
          (newState.Description && newState.Description !== "") &&
          (newState.JiraNumber && newState.JiraNumber !== "")
        ));
    };
  }

  function route(url) {
    history.push(url);
  }

  function getCSVContent (data)  {
    let csv = json2csv.parse(data, { header: true });
    return csv;
  };
/*
This function perform download csv file 
*/
 function handleDownloadCsv (){
    const data = (tabValue==="reviewed")? list.reviewed.SG_RULE:list.tokens
    var blob = new Blob([getCSVContent(data)], { type: 'text/csv' });
      saveAs(blob, (tabValue==="reviewed")?"Request_History":"All_Tokens");
  };

  // Tabs selector
  let tabSelected;
  if (tabValue === "pending") {
    tabSelected =
      list && list.pending && Array.isArray(list.pending.SG_RULE) ? (
        <SgRule
          onDelete={onDelete}
          onReview={onReview}
          onEdit={onEdit}
          title="Pending SG_RULE requests"
          dataPath="$.tokens.list.pending.SG_RULE"
          route={route}
          tabInfo="pending"
        />
      ) : (
        <div>No SG_RULE are pending.</div>
      );
  } else if (tabValue === "reviewed") {
    tabSelected =
      list && list.reviewed && Array.isArray(list.reviewed.SG_RULE) ? (
        <SgRule
          title="Reviewed SG_RULE requests"
          dataPath="$.tokens.list.reviewed.SG_RULE"
          route={route}
          tabInfo="reviewed"
        />
      ) : (
        <div>No SG_RULE have been reviewed yet.</div>
      );
  } else if (tabValue === "tokens") {
    tabSelected =
      list && Array.isArray(list.tokens) ? (
        <SgRule
          title="All tokens approved (at least) once"
          dataPath="$.tokens.list.tokens"
          route={route}
          tabInfo="tokens"
        />
      ) : (
        <div>No tokens yet.</div>
      );
  }

  return (
    <div>
      <DialogBox
        classes={{
          paper: styles.paper
        }}
        id="ringtone-menu"
        keepMounted
        onSubmit={onSubmit}
        onSubmitApprove={onSubmitApprove}
        onSubmitRevoke={onSubmitRevoke}
        onChange={onChange}
        onClose={onClose}
        controller={controller}
        data={data}
        valid={valid}
      />
      <Paper className={classes.root}>
        <div className={classes.addButton}>
        {(tabValue !=='pending') &&<Button
            id="request-download-token"
            onClick={handleDownloadCsv}
            variant="outlined"
            color="primary"
          >
            <ArrowDownward on />
            Download
            </Button>}
          <Button
            id="request-delete-token"
            onClick={onDelete}
            variant="outlined"
            color="primary"
            disabled={location.pathname === "/tokens/delete"}
          >
            <DeleteIcon />
            Request deletion
          </Button>
          <Button
            id="request-new-token"
            style={{ marginRight: "10px" }}
            onClick={onNew}
            variant="outlined"
            color="primary"
            disabled={location.pathname === "/tokens/new"}
          >
            <AddIcon />
            Request a new rule
          </Button>
        </div>

        <Typography variant="h4" style={{ marginBottom: "20px" }}>
          Security approval management
        </Typography>
        <Switch>
          <Route path="/tokens/:id" component={TokensDetails} />
        </Switch>
        <LoadingPanel async={async}>
          {list && (
            <div style={{ marginTop: "20px" }}>
              <Tabs
                value={tabValue}
                onChange={handleChangeTab}
                indicatorColor="primary"
                textColor="primary"
                centered
              >
                <Tab
                  label={`Pending requests (${list.pending && list.pending.SG_RULE ? list.pending.SG_RULE.length : 0
                    })`}
                  value="pending"
                />
                <Tab
                  label={`Requests history (${list.reviewed && list.reviewed.SG_RULE ? list.reviewed.SG_RULE.length : 0
                    })`}
                  value="reviewed"
                />
                <Tab label={`All tokens (${list.tokens ? list.tokens.length : 0})`} value="tokens" />
              </Tabs>
              {tabSelected}
            </div>
          )}
        </LoadingPanel>
      </Paper>
    </div>
  );
}

export default withStyles(styles)(Tokens);
