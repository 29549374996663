import React, { useState, useEffect } from "react";
import ObjectList from "../common/ObjectList";
import { Paper, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { ResponsivePie } from "@nivo/pie";
import { useSelector } from "react-redux";
import { saveAs } from "file-saver";

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3)
  }
});

export default withStyles(styles)(function ({ classes }) {
  const cloudCustomerStackData = useSelector(state => state.cloudprojects.cloudCustomerStackData || {});
  const [viewModel, setViewModel] = useState({ customers: [], versions: [], regions: [] });

  useEffect(() => {
    if (Object.keys(cloudCustomerStackData).length > 0) {
      const result = createViewModel(cloudCustomerStackData);
      setViewModel(result);
    }
  }, [cloudCustomerStackData]);

  const applyFilter = (type, filter) => {
    let result = cloudCustomerStackData;
    if (type !== "ALL") {
      const filterFunction = ([key, value]) => value[type] && value[type] === filter;
      const filteredEntries = Object.entries(cloudCustomerStackData).filter(filterFunction);
      result = Object.fromEntries(filteredEntries);
    }

    const { regions, versions } = viewModel;
    let model = createViewModel(result);
    setViewModel({ ...model, regions, versions });
  };

  const createCSV = (headers, data) => {
    const csvRows = [];
    const headerRow = headers.map(header => header.label);
    csvRows.push(headerRow.join(","));

    const escapeAndQuote = (value) => {
      if (typeof value === 'string') {
        value = value.replace(/"/g, '""');
        if (value.includes(",") || value.includes("\n") || value.includes('"')) {
          value = `"${value}"`;
        }
      }
      return value;
    };

    data.forEach(row => {
      const dataRow = headers.map(header => escapeAndQuote(row[header.id]));
      csvRows.push(dataRow.join(","));
    });
    return csvRows.join("\n");
  };

  const formatDate = (date) => {
    return date.toISOString().split('T')[0];
  };

  const downloadCSV = (headers, data) => {
    try {
      const csv = createCSV(headers, data);
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const today = new Date();
      const fileName = `nuxeo-cloud-customers-${formatDate(today)}.csv`;
      saveAs(blob, fileName);
    }
    catch (error) {
      console.error("An error occurred while generating the CSV:", error);
    }
  };

  const sortDataByValue = (data) => {
    return data.slice().sort((a, b) => b.value - a.value);
  };

  const formatData = (data) => {
    return data.map(datum => ({
      ...datum,
      label: `${datum.id} (${datum.value})`
    }));
  }

  const createViewModel = (services) => {
    let map = {};
    let versions = {};
    let regions = {};
    for (let i in services) {
      let service = services[i];
      if (typeof service !== "object") continue;
      if (service["billing-category"] !== "customers") continue;
      service.customer = service["billing-subcategory"];
      if (!map[service.customer]) {
        map[service.customer] = {};
      }
      service["env"] = service["env"] || service["stack-identifier"];
      versions[service["infrastructure-version"]] = versions[service["infrastructure-version"]] || 0;
      versions[service["infrastructure-version"]]++;
      regions[service.region] = regions[service.region] || 0;
      regions[service.region]++;

      map[service.customer].customer = service.customer;
      let region = service["region"];
      let customersRegion = map[service.customer].region;
      map[service.customer].region = customersRegion ? customersRegion.includes(region) ? customersRegion : `${customersRegion}, ${region}` : region;

      let version = service["infrastructure-version"];
      let customersVersion = map[service.customer].version;
      map[service.customer].version = customersVersion && !customersVersion.includes(version) ? `${customersVersion}, ${version}` : version;

      let customerEnv = map[service.customer][service.environment];
      let env = `${service["infrastructure-version"]} ${service["env"]} (${region})`;
      map[service.customer][service.environment] = customerEnv ? `${customerEnv}, ${env}` : env;
      map[service.customer][service.environment + "Details"] = map[service.customer][service.environment + "Details"] || [];
      map[service.customer][service.environment + "Details"].push(service);
    }
    return {
      customers: Object.keys(map).map(c => map[c]),
      versions: Object.keys(versions).map(r => ({ id: r, label: r, value: versions[r] })),
      regions: Object.keys(regions).map(r => ({ id: r, label: r, value: regions[r] }))
    };
  };

  const headers = [
    {
      id: "customer",
      numeric: false,
      disablePadding: false,
      label: "Name"
    },
    {
      id: "region",
      numeric: false,
      disablePadding: false,
      label: "Region"
    },
    {
      id: "preprod",
      numeric: false,
      disablePadding: false,
      label: "Preprod"
    },
    {
      id: "prod",
      numeric: false,
      disablePadding: false,
      label: "Production"
    },
    {
      id: "uat",
      numeric: false,
      disablePadding: false,
      label: "UAT"
    },
    {
      id: "dev",
      numeric: false,
      disablePadding: false,
      label: "Development"
    }
  ];
  return (
    <Paper className={classes.root}>
      <div
        style={{
          width: "100%",
          height: 400,
          flex: 1,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-around"
        }}
      >
        <div style={{ width: 600, height: 400 }}>
          <div>Environment Per Regions</div>
          <ResponsivePie
            data={sortDataByValue(formatData(viewModel.regions))}
            margin={{ top: 40, right: 220, bottom: 40, left: 140 }}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            activeOuterRadiusOffset={8}
            width={600}
            height={400}
            colors={{ scheme: 'paired' }}
            borderWidth={1}
            borderColor={{ from: "color", modifiers: [["darker", 0.6]] }}
            arcLinkLabel={e => e.id + " (" + e.value + ")"}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor="#333333"
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{ from: 'color' }}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor="#333333"
            animate={true}
            motionStiffness={90}
            motionDamping={15}
            enableRadialLabels={false}
            onClick={(arc) => applyFilter('region', arc.id)}
            defs={[
              {
                id: 'dots',
                type: 'patternDots',
                background: 'inherit',
                color: 'rgba(255, 255, 255, 0.3)',
                size: 6,
                padding: 1,
                stagger: true
              },
              {
                id: 'lines',
                type: 'patternLines',
                background: 'inherit',
                color: 'rgba(255, 255, 255, 0.3)',
                rotation: -45,
                lineWidth: 6,
                spacing: 10
              }
            ]}
            legends={[
              {
                anchor: 'right',
                direction: 'column',
                justify: false,
                translateX: 160,
                translateY: 0,
                itemsSpacing: 6,
                itemWidth: 60,
                itemHeight: 18,
                itemTextColor: '#999',
                itemDirection: 'left-to-right',
                itemOpacity: 1,
                symbolSize: 14,
                symbolShape: 'circle'
              }
            ]}
          />
        </div>
        <div style={{ width: 600, height: 400 }}>
          <div>Environment Per Versions</div>
          <ResponsivePie
            data={sortDataByValue(formatData(viewModel.versions))}
            margin={{ top: 40, right: 220, bottom: 40, left: 140 }}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            activeOuterRadiusOffset={8}
            width={600}
            height={400}
            colors={{ scheme: 'paired' }}
            borderWidth={1}
            borderColor={{ from: "color", modifiers: [["darker", 0.6]] }}
            arcLinkLabel={e => e.id + " (" + e.value + ")"}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor="#333333"
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{ from: 'color' }}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor="#333333"
            animate={true}
            motionStiffness={90}
            motionDamping={15}
            enableRadialLabels={false}
            onClick={(arc) => applyFilter('infrastructure-version', arc.id)}
            defs={[
              {
                id: 'dots',
                type: 'patternDots',
                background: 'inherit',
                color: 'rgba(255, 255, 255, 0.3)',
                size: 6,
                padding: 1,
                stagger: true
              },
              {
                id: 'lines',
                type: 'patternLines',
                background: 'inherit',
                color: 'rgba(255, 255, 255, 0.3)',
                rotation: -45,
                lineWidth: 6,
                spacing: 10
              }
            ]}
            legends={[
              {
                anchor: 'right',
                direction: 'column',
                justify: false,
                translateX: 160,
                translateY: 0,
                itemsSpacing: 6,
                itemWidth: 60,
                itemHeight: 18,
                itemTextColor: '#999',
                itemDirection: 'left-to-right',
                itemOpacity: 1,
                symbolSize: 14,
                symbolShape: 'circle'
              }
            ]}
          />
        </div>
      </div>
      <div style={{ textAlign: 'right', padding: `0px 10px 25px 0px` }}>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => applyFilter('ALL')}
          style={{ marginRight: 10 }}
        >
          CLEAR FILTER
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => downloadCSV(headers, viewModel.customers)}
        >
          Download CSV
        </Button>
      </div>
      <div>
        <ObjectList
          noPaper
          title={`Customers (${viewModel.customers.length})`}
          orderBy="customer"
          order="desc"
          headers={headers}
          data={viewModel.customers}
        />
      </div>
    </Paper>
  );
});
