import { Controller } from "./Controller";
import { toast } from "react-toastify";
import * as pako from "pako";

class CloudProjectsController extends Controller {
  history: any;
  location: any;
  project: any;
  constructor() {
    super("cloudprojects", { projects: [], current: {} });
    this.registerRoute({ path: "/customers", strict: false, exact: true }, this._fetchCustomers);
    this.registerRoute({ path: "/cloudprojects/:id", strict: false, exact: true }, this._fetchProjectRoute);
    this.registerRoute(
      { path: "/cloudprojects/:id/:env/container/:containerID", strict: false, exact: true },
      this._fetchProjectRoute
    );
    this.registerRoute(
      { path: "/cloudprojects/:id/:serverID", strict: false, exact: true },
      this._fetchProjectRoute
    );
  }

  async handleSyncAction(action) {
    if (action.type === "UPDATE") {
      await this.ajax("/tasks/" + action.id, "PATCH", action.params);
      return;
    }
  }

  getURL(url) {
    return Controller.endpoint + "/cloudprojects/" + url;
  }

  getLogs(project, env, index, state: any = undefined, format: string = "CSV", callback = () => { }) {
    let { customColumns, eventSearch, timeRange, exportLimit, logformat } = state;
    this.asyncAction("CSV_CLOUDPROJECT", async (dispatch, getState) => {
      // search query
      let must = [];
      if (eventSearch) {
        must.push([{ query_string: { query: eventSearch } }]);
      }

      // Time range
      if (timeRange.indexOf(",") > -1) {
        let [gte, lte] = timeRange.split(",");
        let range = {
          range: {
            timestamp: {
              gte: gte ? new Date(gte.replace(/ /g, "T") + "Z").getTime() : 0,
              lte: lte ? new Date(lte.replace(/ /g, "T") + "Z").getTime() : Date.now()
            }
          }
        };
        must.push(range);
      }

      let bd = { bool: { must_not: [{ match: { type: "bd" } }, { match: { type: "op" } }] } };
      must.push(bd);

      let body = {
        query: { bool: { must } },
        size: Number(exportLimit),
        _source: { includes: ["*"], excludes: [] },
        from: 0,
        sort: [{ timestamp: { order: "desc" } }]
      };

      let { url } = await this.ajax(
        `/cloudprojects/${project}/${env}/logsExport/${index}/_msearch?format=${format}&customColumns=${escape(
          JSON.stringify(customColumns)
        )}&logformat=${escape(logformat)}`,
        "POST",
        `{"preference":"SearchResult"}\n${JSON.stringify(body)}\n`,
        { raw: true, headers: { "content-type": "application/x-ndjson" } }
      );
      window.location = url;
      callback();
    });
  }

  onCSV_CLOUDPROJECT(state, action) {
    return {
      ...state,
      csvLoader: action.csvLoader
    };
  }
  init() {
    super.init();
    return false;
  }

  create(project) {
    this.asyncAction("CREATE_CLOUDPROJECT", async (dispatch, getState) => {
      let result: any = await this.ajax("/cloudprojects", "POST", project);
      // Should reroute clean
      let projects = this.getLocalState().projects.slice(0);
      projects.push(result);
      this.history.push("/cloudprojects/" + project.uuid);
      return { current: result, projects };
    });
  }

  update(uuid, updates, callback) {
    this.asyncAction(
      "UPDATE_CLOUDPROJECT",
      async (dispatch, getState) => {
        await this.ajax("/cloudprojects/" + uuid + "/update", "PATCH", updates).
          then(response => {
            let projects = this.getLocalState().projects.map(proj => {
              if (proj.uuid === uuid) {
                return response;
              }
              return proj;
            });
            return { current: response, projects };
          })
          .catch(err => {
            let msg = err.code === 403
              ? "Access denied. You don't have the necessary privileges to proceed. Please contact the Security Admin."
              : err.code === 500
                ? "Server error. Please reach out to the Security team."
                : "An unexpected error occurred. Please contact the Security team.";


            toast.error(msg, {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 6000
            });
          });
      },
      () => {
        if (callback) {
          callback();
        }
      }
    );
  }


  get(uuid) {
    this.asyncAction("GET_CLOUDPROJECT", async (dispatch, getState) => {
      await Controller.get("users").waitInit();
      let project: any = await this.ajax(`/cloudprojects/${uuid}`);
      // Ensure name is present in Docker image
      for (let e in project.envs) {
        if (project.envs[e].services && project.envs[e].services.docker && project.envs[e].services.docker.containers) {
          for (let d in project.envs[e].services.docker.containers) {
            project.envs[e].services.docker.containers[d].name = d;
          }
        }

        let nessusReport: { html: any; }, scanHistory: any;
        await Promise.allSettled([
          this.ajax(`/cloudprojects/${uuid}/scans/${e}/report`).then(response => {
            nessusReport = response;
          }),
          this.ajax(`/cloudprojects/${uuid}/scans/${e}/history`).then(response => {
            scanHistory = response;
          })
        ]);
        project.envs[e] = {
          ...project.envs[e],
          nessusReport: nessusReport.html
        };
        project.envs[e] = {
          ...project.envs[e],
          scanHistory: scanHistory
        };
      }
      return { current: project };
    });

    this.asyncAction("GET_CLOUDPROJECT_COST", async (dispatch, getState) => {
      await Controller.get("users").waitInit();
      if (!this.hasPermission("cloudProjectsCosts")) {
        return;
      }
      let projectCosts = await this.ajax(`/cloudprojects/${uuid}/costs`);
      return { currentCost: projectCosts };
    });
    this.asyncAction("GET_CLOUDPROJECT_SERVERS", async (dispatch, getState) => {
      await Controller.get("users").waitInit();
      let projectServers = await this.ajax(`/cloudprojects/${uuid}/servers`);
      return { currentServers: projectServers };
    });
  }

  getPdf(uuid, env) {
    this.asyncAction("GET_CLOUDPROJECT_LAST_SCAN_PDF", async (dispatch, getState) => {
      let { location } = await this.ajax(`/cloudprojects/${uuid}/scans/${env}/pdf`);
      window.open(location);
    });
  }

  onROUTE_CHANGED(state, action) {
    this.project = state.current || {}
    this.location = action.location;
    if (action.history) {
      this.history = action.history;
    }
    return state;
  }

  _fetchCustomers() {
    this.asyncAction(
      "GET_CUSTOMERS",
      async (dispatch, getState) => {
        let services: any = await this.ajax("/dashboard/tagsFromStackId");
        return {
          cloudCustomerStackData: services
        };
      },
      () => {
        this.setInitialized();
      }
    );
  }

  _fetchProjectRoute(params) {
    if (params.id !== "new" && params.id !== this.project.uuid) {
      this.get(params.id);
    }
  }

  afterLOGIN_SUCCESS() {
    this.asyncAction(
      "GET_DEFAULT_SERVICES",
      async (dispatch, getState) => {
        let services: any = await this.ajax("/dashboard/server_services");
        return { defaultServices: services };
      },
      () => {
        this.setInitialized();
      }
    );
    this.asyncAction("SYNC_PROJECTS", async (dispatch, getState) => {
      let projects: any = await this.ajax("/cloudprojects/index");
      projects.forEach(project => {
        project.link = "/cloudprojects/" + project.uuid;
      });

      localStorage.removeItem("cloudprojects");
      await this.waitInit();
      localStorage.setItem("cloudprojects", pako.gzip(JSON.stringify(projects), { to: 'string' }));
      return { projects };
    });
  }

  getDefaultConfig(service, type, override) {
    let defaultServices = this.getLocalState().defaultServices;
    let defaultService = defaultServices.services[service] || {};
    let defaultEnv = {};
    if (defaultServices.envs[type]) {
      defaultEnv = defaultServices.envs[type].services[service];
    }
    let result = {
      ...defaultService,
      ...defaultEnv
    };
    result.strict = result.strict || {};
    if (this.hasPermission("cloudProjectsOverride") && override) {
      result.strict = {};
    }
    return result;
  }

  deleteContainer(cloudProject, env, container, callback) {
    this.asyncAction(
      "DELETE_CONTAINER",
      async (dispatch, getState) => {
        let containers = { ...cloudProject.envs[env].services.docker.containers };
        delete containers[container];
        cloudProject.envs[env].services.docker.containers = containers;
        await this.ajax("/cloudprojects/" + cloudProject.uuid + "/update", "PATCH", cloudProject);
        return { current: cloudProject };
      },
      callback
    );
  }
}
export { CloudProjectsController };
