import { TimeSeries } from "pondjs";
import React from "react";
import { AreaChart, ChartContainer, ChartRow, Charts, Legend, Resizable, styler, YAxis } from "react-timeseries-charts";
import _ from "underscore";

import { Checkbox, FormControlLabel } from "@material-ui/core";

class CloudProjectHistoryScan extends React.Component {
  constructor(props) {
    super(props);
    let unstackedRawData = props.data;
    let rawData = [];
    for (let service in unstackedRawData) {
      let formatServiceData = {
        key: service,
        values: unstackedRawData[service]
      };
      rawData = [...rawData, formatServiceData];
    }
    const numPoints = rawData[0] && rawData[0].values ? rawData[0].values.length : 0;
    const columnNames = rawData.map(d => {
      return d.key;
    });
    const name = "series";
    const points = [];
    let aggregation = 86400000;
    let aggregate = 0;
    for (let i = 0; i < numPoints; i++) {
      const t = rawData[0].values[i][0];
      if (Math.ceil(t / aggregation) === aggregate) {
        const point = points[points.length - 1];
        let j = 1;
       _.each(rawData, d => {
            point[j] = point[j] + d.values[i][1];
            j++;
        });
      } else {
        const point = [t];
        _.each(rawData, d => {
            point.push(d.values[i][1]);
        });
        points.push(point);
        aggregate = Math.floor(t / aggregation);
      }
    }
    const columns = ["time", ...columnNames];
    const series = new TimeSeries({ name, columns, points });
    const timeRange = series.range();
    let columsHashMap=new Map();
    for(let i=0;i<columnNames.length;i++){
      columsHashMap.set(columnNames[i],true)
    }
    this.state = {
      unstackedRawData,
      series: series || [],
      timeRange: timeRange || [],
      columnNames,
      columns: JSON.parse(JSON.stringify(columnNames)),
      scheme: "Paired",
      hidden: {},
      columnsFilter:this.sortChartByPriority(JSON.parse(JSON.stringify(columnNames))),
      columnsSelected: this.sortChartByPriority(JSON.parse(JSON.stringify(columnNames))),
      columsHashMap:columsHashMap,
    };
  }

  nbVisible() {
    let nbHidden = 0;
    for (let i in this.state.hidden) {
      if (this.state.hidden[i]) nbHidden++;
    }
    return this.state.columns.length - nbHidden;
  }

  sortChartByPriority(chartArr){
    let pirority=["critical","high","medium","low"]
    let sortedArr=[]
    for(let i=0;i<pirority.length;i++){
      if(chartArr.indexOf(pirority[i])!==-1){
        sortedArr.push(pirority[i]);
      }
    }
    return sortedArr;
  }

  handleTimeRangeChange = timeRange => {
    this.setState({ timeRange: timeRange });
  };

  handleFilterBox=(column)=>{
    let {columnsSelected,columsHashMap}=this.state
    if(columsHashMap.get(column) && columnsSelected.length!==1){
      let tempColumnSelected=columnsSelected 
       if(tempColumnSelected){
        let coulmnIndex=tempColumnSelected?.indexOf(column)
        if(coulmnIndex!==-1)tempColumnSelected.splice(coulmnIndex, 1);
        this.setState({
          columsHashMap:this.state.columsHashMap.set(column,false),
          columnsSelected:this.sortChartByPriority(tempColumnSelected)
        })
       }
    }else if(columnsSelected.length!==1 ||(columnsSelected.length===1 && !columsHashMap.get(column) ) ){
      let tempColumnSelected=columnsSelected 
      tempColumnSelected.push(column)
      this.setState({
        columsHashMap:this.state.columsHashMap.set(column,true),
        columnsSelected:this.sortChartByPriority(tempColumnSelected)
      })
    } 
  }

  renderChart = (series, timeRange, columnNames, style,columnsSelected) => {
    if (!timeRange || (!timeRange.length && !Object.keys(timeRange).length)) {
      return;
    }
    const axisType = "linear";
    const interpolationType = "curveLinear";
    const cols = { up: columnsSelected, down: [] };
    let croppedSeries = series.crop(timeRange);
    let maxArray = [];
    for (let i in columnsSelected) {
      maxArray.push(croppedSeries.max(columnsSelected[i]));
    }
    let min = 0;
    let max = maxArray.reduce((accumulator, currentValue) => (typeof(currentValue)=="number")? accumulator + currentValue:accumulator)
    return (
      <ChartContainer
        timeRange={timeRange}
        onBackgroundClick={() => this.setState({ selection: null })}
        enablePanZoom={false}
        onTimeRangeChanged={this.handleTimeRangeChange}
      >
        <ChartRow height="350">
          <YAxis
            id="value"
            min={min}
            max={max}
            width="50"
            type={axisType}
            showGrid={true}
            label="No of Hosts"
            format=".2f"
          />
          <Charts>
            <AreaChart
              axis="value"
              style={style}
              series={croppedSeries}
              columns={cols}
              fillOpacity={0.4}
              interpolation={interpolationType}
              highlight={this.state.highlight}
              onHighlightChange={highlight => this.setState({ highlight })}
              selection={this.state.selection}
              onSelectionChange={selection => this.setState({ selection })}
            />
          </Charts>
        </ChartRow>
      </ChartContainer>
    );
  };

  render() {
    const { series, columnNames, timeRange, scheme,columnsSelected } = this.state;
    const legendCategories = this.sortChartByPriority(columnNames).map(d => ({ key: d, label: d }));
    let temp=[{key:'critical',color:"#91243E"},{key:'high',color:"#DD4B50"},{key:'medium',color:"#F18C43"},{key:'low',color:"#F8C851"}]

    const style = styler(temp, scheme);
    return (
      <div>
        <div className="row">
          <div className="col-md-9" style={{ padding: "20px" }}>
            <Legend categories={legendCategories} style={style} type="swatch" />
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-md-12">
            {timeRange && (timeRange.length || Object.keys(timeRange).length) && (
              <Resizable>{this.renderChart(series, timeRange, columnNames, style,columnsSelected)}</Resizable>
            )}
            <div>
             {this.state.columnsFilter && this.state.columnsFilter.map((column)=>(
                <FormControlLabel
                key={column}
                control={
                  <Checkbox
                    value={column}  
                    checked={this.state.columsHashMap.get(column)} 
                    onClick={()=>{this.handleFilterBox(column)}}
                  />
                }
                label={column}
                />
             ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CloudProjectHistoryScan;