import { toast } from "react-toastify";
import { Controller } from "./Controller";
class MFAController extends Controller {
  constructor() {
    super("mfa", { requiredMFA: false, _async: {} });
  }

  // Choosing push verify
  async push(body, factorKey, action, pushId = null) {
    let { hash } = this.getLocalState();
    console.log(`MFA Hash: ${hash}`)
    this.ajax(`/mfa/${factorKey}/${hash}`, "POST", body, {
      headers: pushId ? { "x-mfa-pushid": pushId } : {}
    })
      .then(async r => {
        if (r && r.headers) {
          switch (r.headers.get("x-mfa-status")) {
            case "wait":
              // MFA validation not received yet, retrying in `delay` seconds
              let { delay } = await r.json(),
                pushId = r.headers.get("x-mfa-pushid");
              setTimeout(() => {
                this.push(body, factorKey, action, pushId);
              }, delay * 1000);
              toast.info("Waiting for you push confirmation...", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: delay * 1000
              });
              return;
            case "success":
              // Do the action asked
              toast.success("Your MFA push has been confirmed !", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 10000
              });
              this.hide();
              Controller.dispatch({ type: action, response: await r.json() });
              return;
          }
          toast.error("Your MFA push cancelled due to an unknown error", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 10000
          });
          this.hide();
        }
      })
      .catch(err => {
        if (err.code === 403) {
          toast.error("Access denied. You do not have the necessary privileges to proceed. Please contact Security-Admin.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 6000
          });
        }
        else {
          toast.error("The target action has been cancelled due to an unknown error", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 10000
          });
          this.hide();
        }
      });
  }

  // Choosing totp verify
  async totp(body, factorKey, action, totp) {
    let { hash } = this.getLocalState();
    this.ajax(`/mfa/${factorKey}/${hash}`, "POST", body, {
      headers: { "x-mfa-totp": totp }
    })
      .then(async r => {
        if (r && r.headers && r.headers.get("x-mfa-status") === "success") {
          // Do the action asked
          toast.success("Your MFA TOTP has been confirmed !", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 10000
          });
          Controller.dispatch({ type: action, response: await r.json() });
        }
      })
      .catch(async err => {
        // There has been an error
        toast.error("MFA TOTP code is invalid, try again ?", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 10000
        });
      })
      .finally(async () => {
        this.hide();
      });
  }

  // Displaying the MFA control box
  show(hash, factors, options) {
    let { action, body } = options;
    Controller.dispatch({
      type: "SHOW_MFA_DIALOG",
      factors,
      hash,
      action,
      body
    });
  }

  onSHOW_MFA_DIALOG(state, action) {
    return {
      ...state,
      factors: action.factors,
      hash: action.hash,
      body: action.body,
      requiredMFA: true,
      action: action.action
    };
  }

  // Hiding the MFA control box
  hide() {
    Controller.dispatch({ type: "HIDE_MFA_DIALOG" });
  }

  onHIDE_MFA_DIALOG(state) {
    return {
      ...state,
      hash: "",
      requiredMFA: false
    };
  }
}
export { MFAController };
