import React from "react";
import { useSelector } from "react-redux";
import { Controller } from "./controllers";
import LoadingPanel from "./common/LoadingPanel";
import { Paper, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import DeployIcon from "@material-ui/icons/Launch";
import { useParams } from 'react-router-dom';

const styles = {
  root: {
    height: 400,
    padding: 20,
    marginTop: 20
  },
  addButton: {
    marginTop: "20px",
    flex: 1,
    flexDirection: "row-reverse",
    display: "flex"
  },
  formContainer: {
    padding: "30px"
  }
};

const DeployBeta = ({ classes }) => {
  const async = useSelector(state => state.dashboard._async.DEPLOY_RELEASE || {});
  const { id: project } = useParams();
  let warning;
  let deployable = false;
  if (!isDeployable()) {
    warning = <div style={{ color: "red" }}>Required to be on https://beta.soc.nuxeo.com</div>;
  } else {
    deployable = true;
  }

  const deployNewRelease = () => {
    Controller.get("dashboard").deployNewRelease(project);
  };

  function isDeployable() {
    return window.location.host === "beta.soc.nuxeo.com";
  }

  return (
    <div>
      <Paper className={classes.root}>
        Deploy Beta {warning}
        <div className={classes.addButton}>
          <LoadingPanel async={async}>
            <Button
              disabled={!deployable}
              onClick={() => {
                deployNewRelease();
              }}
              variant="outlined"
              color="primary"
            >
              <DeployIcon className={classes.deployIcon} />
              DEPLOY BETA
            </Button>
          </LoadingPanel>
        </div>
      </Paper>
    </div>
  );
};

export default withStyles(styles)(DeployBeta);
