import React from "react";
import ObjectList from "../common/ObjectList";
import { Button, IconButton, Tooltip } from "@material-ui/core";
import IngressIcon from "@material-ui/icons/CloudDownload";
import EgressIcon from "@material-ui/icons/CloudUpload";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import RevokeIcon from "@material-ui/icons/PanTool";
import ApproveIcon from "@material-ui/icons/ThumbUp";
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import AddIcon from "@material-ui/icons/Add";
import { Controller } from "../controllers";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

const SgRule = ({ title, dataPath, onReview, route, tabInfo, onEdit }) => {
  let headers = [
    {
      id: "TokenId",
      numeric: false,
      disablePadding: false,
      getCellWidget: n => {
        return <div>{n.TokenId || "-"}</div>;
      },
      label: "Token"
    },
    {
      id: "ts",
      getCellWidget: n => {
        return <div>{new Date(n.ts).toLocaleString()}</div>;
      },
      numeric: true,
      disablePadding: false,
      label: "Date"
    },
    {
      id: "GroupName",
      numeric: false,
      getCellWidget: n => {
        return <div style={{ fontWeight: "bold" }}>{n.GroupName}</div>;
      },
      disablePadding: false,
      label: "Group name"
    },
    {
      id: "Target",
      numeric: false,
      getCellWidget: n => {
        return <div style={{ fontWeight: "bold" }}>{n.Target}</div>;
      },
      disablePadding: false,
      label: "Target"
    },
    {
      id: "Description",
      numeric: false,
      disablePadding: false,
      label: "Description"
    },
    {
      id: "Type",
      numeric: false,
      disablePadding: false,
      label: "*gress",
      getCellWidget: n => {
        return (
          <Tooltip title={n.Type || "Unknown type"}>
            {n.Type === "EGRESS" ? <EgressIcon /> : <IngressIcon color="secondary" />}
          </Tooltip>
        );
      }
    },
    {
      id: "IpProtocol",
      numeric: false,
      disablePadding: false,
      label: "Protocol"
    },
    {
      id: "FirewallRequest",
      numeric: false,
      disablePadding: false,
      label: "Firewall Request"
    },
    {
      id: "ToPort",
      numeric: false,
      disablePadding: false,
      label: "Port"
    },
    {
      id: "JiraNumber",
      numeric: false,
      disablePadding: false,
      label: "JIRA"
    },
    {
      id: "login",
      numeric: false,
      disablePadding: false,
      label: "Claimant"
    },
    {
      id: "Status",
      numeric: false,
      disablePadding: false,
      getCellWidget: n => {
        let s =n.Status ;
        if (onReview) {
          s = "pending";
        }

        let tip = s;

        if (n.reviews) {
          if (s === "REVIEWED" && n.reviews.length) {
            // If reviewed, digging latest status in {n.reviews}
            // TODO: cleanup all unknown status in token table and update status field, keep status and not Status, ensure every reviews[] are existing
            // s = n.reviews[n.reviews.length - 1].reviewType;
          }
          // Build tooltip with all successive reviews if found
          tip = n.reviews.map(r => `${r.reviewType} (${new Date(r.reviewDate).toISOString()})`).join(", ");
        }
        return (
          <Tooltip title={tip || "Unknown result"}>
            {s === "pending" ? (
              <HourglassEmptyIcon color="primary" />
            ) : s === "APPROVED" ? (
              <ApproveIcon color="primary" />
            ) : s === "REVOKED" ? (
              <RevokeIcon color="secondary" />
            ) : s === "DELETED" ? (
              <DeleteIcon color="secondary" />
            ) : s === "REVIEWED" ? (
              <ApproveIcon style={{ color: 'orange' }} />
            ) : (
              <ReportProblemIcon color="error" />
            )
            }
          </Tooltip>
        );
      },
      label: "Status"
    }
  ];

  if (onReview) {
    headers.unshift({
      id: "RequestType",
      numeric: false,
      disablePadding: false,
      label: "Request type",
      getCellWidget: n => {
        return (
          <Tooltip title={n.RequestType || "Unknown"}>
            {n.RequestType === "delete" ? (
              <DeleteIcon color="secondary" />
            ) : n.RequestType === "create" ? (
              <AddIcon color="primary" />
            ) : n.RequestType === "update" ? (
              <AddIcon color="secondary" />
            ) : (
              <ContactSupportIcon color="disabled" />
            )}
          </Tooltip>
        );
      }
    });
    if (Controller.get("users").hasPermission("tokensAdmin")) {
      headers.push({
        id: "Actions",
        numeric: false,
        disablePadding: false,
        getCellWidget: n => {
          return (
            <div key="actions">
              {Controller.get("users").hasPermission("tokensAdmin") && n.RequestType === "delete" && (
                <Tooltip title="Delete">
                  <Button
                    onClick={() => {
                      onReview("delete", { TokenType: "SG_RULE", ...n });
                    }}
                    variant="outlined"
                    color="secondary"
                    style={{ marginRight: "0.5em", marginBottom: "0.5em" }}
                  >
                    <DeleteIcon color="secondary" />
                  </Button>
                </Tooltip>
              )}
              {Controller.get("users").hasPermission("tokensAdmin") && n.RequestType !== "delete" && (
                <Tooltip title="Revoke">
                  <Button
                    onClick={() => {
                      onReview("revoke", n);
                    }}
                    variant="outlined"
                    color="secondary"
                    style={{ marginRight: "0.5em", marginBottom: "0.5em" }}
                  >
                    <RevokeIcon color="secondary" />
                  </Button>
                </Tooltip>
              )}
              {Controller.get("users").hasPermission("tokensAdmin") && n.RequestType !== "delete" && (
                <Tooltip title="Approve">
                  <Button
                    onClick={() => {
                      onReview("approve", n);
                    }}
                    variant="outlined"
                    color="primary"
                    style={{ marginRight: "0.5em", marginBottom: "0.5em" }}
                  >
                    <ApproveIcon color="primary" />
                  </Button>
                </Tooltip>
              )}
              {Controller.get("users").hasPermission("tokensAdmin") && n.RequestType !== "delete" && tabInfo === "pending" && (
                <Tooltip title="Edit">
                  <Button
                    onClick={() => {
                      onEdit(n);
                    }}
                    variant="outlined"
                    color="primary"
                    style={{ marginRight: "0.5em", marginBottom: "0.5em" }}
                  >
                    <EditIcon color="primary" />
                  </Button>
                </Tooltip>
              )}
              {n.TokenId && (
                <Tooltip title="Zoom details">
                  <Button
                    onClick={() => {
                      route(`/cloud-inventory/_search/${n.TokenId}`); // TODO: replace tokenid per (security)groupid
                    }}
                    variant="outlined"
                    style={{ marginRight: "0.5em", marginBottom: "0.5em" }}
                  >
                    <ZoomInIcon />
                  </Button>
                </Tooltip>
              )}
            </div>
          );
        },
        label: "Actions"
      });
    }
  } else {
    headers.push({
      id: "ReviewLogin",
      disablePadding: false,
      label: "Reviewer",
      numeric: false
    });
    headers.push({
      id: "ReviewDate",
      disablePadding: false,
      label: "Date review",
      getCellWidget: n => {
        return <div>{n.ReviewDate?new Date(n.ReviewDate).toLocaleString():new Date(n.signedDate).toLocaleString()}</div>;
      },
      numeric: true
    });
    headers.push({
      id: "Actions",
      numeric: false,
      disablePadding: false,
      getCellWidget: n => {
        return (
          n.TokenId && (
            <div key="actions">
              <Tooltip title="Zoom details">
                <IconButton
                  onClick={() => {
                    route(`/tokens/${n.TokenId}`);
                  }}
                  variant="outlined"
                >
                  <ZoomInIcon />
                </IconButton>
              </Tooltip>
            </div>
          )
        );
      },
      label: "Actions"
    });
  }

  
  return (
    <ObjectList noPaper title={title} headers={headers} storeData={dataPath} defaultOrderBy="Date" defaultOrder="asc" />
  );
};

export default SgRule;
