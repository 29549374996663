import React from "react";
import { useSelector } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import LoadingPanel from "../common/LoadingPanel";
import AmazonMachinesImage from "./AmazonMachineImage";
import { Typography } from "@material-ui/core";
import AddNewButton from "../common/AddNewButton";

const styles = theme => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "10px",
    maxWidth: "1200px"
  },
  amiStatus: {
    color: "red",
    fontWeight: "bold"
   },
  addButton: {
    position: "relative",
    flex: 1,
    flexDirection: "row-reverse",
    display: "flex"
  },
  amiNoticeDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "10px",
    width: "85vw",
    flexWrap: "wrap"
  }
});

const AmazonMachinesImages = props => {
  const { classes } = props;
  const amis = useSelector(state => state.dashboard.amis.amis || {});
  const async = useSelector(state => state.dashboard._async.SYNC_AMIS);
  return (
    <div className={classes.root}>
      <AddNewButton {...props} label="Add new AMI" disabled={true} />
      <div className={classes.container} id="amazonMachinesImages">
        <LoadingPanel async={async}>
          <Typography variant="h6">AMIS</Typography>
          <Typography variant="h8">AMIs tagged with the <sup className={classes.amiStatus}>Deprecated</sup> flag are no longer built by <a href="https://github.com/nuxeo/nuxeo-security-ami-packer">Nuxeo Security AMI Packer</a> </Typography>
          {Object.keys(amis).map((key, i) => {
            return <AmazonMachinesImage key={i} ami={amis[key]} />;
          })}
        </LoadingPanel>
      </div>
    </div>
  );
};
export default withStyles(styles)(AmazonMachinesImages);
