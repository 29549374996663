import React, { useState } from "react";
import { Button, Paper, Tab, Tabs, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import { useSelector } from "react-redux";
import LoadingPanel from "../common/LoadingPanel";
import WorkSpacesDialogBox from "./WorkSpacesDialogBox";
import { Controller } from "../controllers";
import CachedIcon from "@material-ui/icons/Cached";

import {
    WorkspacePendingRequestsGrid,
    WorkspaceRequestHistoryGrid,
    WorkspaceDetailsGrid,
    WorkspaceReviewedRequestsGrid
} from "./WorkspacesDataGrids";
import { TabValue, DialogAction, RequestStatus, WorkspaceRequest } from "../controllers/WorkSpacesController";

const styles = theme => ({
    root: {
        padding: 20,
        marginTop: 20
    },
    addButton: {
        display: "flex",
        justifyContent: "flex-end"
    }
});



const WorkSpaces = ({ classes, history, location }) => {
    const state = useSelector(state => state.workspaces);
    const async = state._async.FETCH_WORKSPACE_ITEMS || {};
    const items = state.items;
    const reviewedItem = items.reviewed || [];
    const approvedItems = reviewedItem.filter(item => [RequestStatus.APPROVED, RequestStatus.REVOKED].includes(item.status)) || [];
    const pendingItems = items.pending || [];
    const historicalItems = items.history || [];
    const dialogInput = state.dialogInput;
    const selectedTab = state.selectedTab;
    const sorting = state.sorting;
    const filter = state.filter;
    const uiconfig = items.uiconfig || {}


    const onTabSelectionChange = (event, newTab) => {
        Controller.get("workspaces").updateState({ selectedTab: newTab });
    }

    const onNewRequest = () => {
        Controller.get("workspaces").openDialog(DialogAction.NEW_REQUEST);
    };

    const onRefresh = () => {
        Controller.get("workspaces").fetchWorkSpaceItems();
    }

    const onClose = () => Controller.get("workspaces").closeDialog();

    const createDataGridOptions = (tabValue) => {
        const options = {};

        const handleAction = async (status, request, actionType) => {
            let confirmOptions = {
                onOK: async () => {
                    Controller.get("workspaces").closeDialog();
                    request.status = status;
                    if (actionType === 'review') {
                        await Controller.get("workspaces").reviewWorkspaceRequest(request);
                    } else if (actionType === 'submit') {
                        await Controller.get("workspaces").submitWorkspaceRequest(request);
                    }
                }
            }
            if (status === RequestStatus.REVOKED) {
                confirmOptions.textConfirmationForm = true;
                confirmOptions.confirmationText = "Revoke";
                confirmOptions.message = "Are you sure you want to revoke the workspace permanently ? This action cannot be undone."
            } 
            else if (status === RequestStatus.DELETED) {
                confirmOptions.textConfirmationForm = true;
                confirmOptions.confirmationText = "Delete";
                confirmOptions.message = "Are you sure you want to delete the workspace request permanently ? This action cannot be undone."
            }
            else {
                confirmOptions.message = "Do you want to perform this operation?";
            }

            Controller.get("workspaces").openDialog(DialogAction.WARNING, confirmOptions);
        };

        if ([TabValue.PENDING, TabValue.WORKSPACES].includes(tabValue)) {
            options.review = (status, request) => handleAction(status, request, 'review');
        }

        if (tabValue === TabValue.PENDING) {
            options.uiconfig = uiconfig;
            options.onEdit = (request) => {
                Controller.get("workspaces").openDialog(DialogAction.EDIT_REQUEST, request);
            };

            options.onDelete = (request) => handleAction(RequestStatus.DELETED, request, 'submit');
        }

        if (tabValue === TabValue.WORKSPACES) {
            options.showWorkspaceDetails = (request) => {
                Controller.get("workspaces").openDialog(DialogAction.WORKSPACE_DETAILS, request);
            };
        }
        return options;
    }

    const handleSortAndFilterState = (key, value) => {
        console.log(`handleSortAndFilterState: ${[key]}: ${JSON.stringify(value)}`)
        if (key === "filter") {
            filter[selectedTab] = value;
            Controller.get("workspaces").updateState(filter);
        } else if (key === "sorting") {
            sorting[selectedTab] = value
            Controller.get("workspaces").updateState(sorting);
        }
    };

    const getTabContent = (data, title, Component, options = {}) => (
        data && Array.isArray(data) ? <Component
            title={title}
            data={data}
            options={options}
            sorting={sorting[selectedTab]}
            filter={filter[selectedTab]}
            handleSortAndFilterState={handleSortAndFilterState}
        /> : <div>No workspaces yet.</div>
    );

    const renderSelectedTab = () => {
        const tabContents = {
            [TabValue.WORKSPACES]: getTabContent(approvedItems, "Workspaces", WorkspaceDetailsGrid, createDataGridOptions(TabValue.WORKSPACES)),
            [TabValue.PENDING]: getTabContent(pendingItems, "Pending Workspaces Requests", WorkspacePendingRequestsGrid, createDataGridOptions(TabValue.PENDING)),
            [TabValue.REVIEWED]: getTabContent(reviewedItem, "Reviewed Workspaces Requests", WorkspaceReviewedRequestsGrid, createDataGridOptions(TabValue.REVIEWED)),
            [TabValue.HISTORY]: getTabContent(historicalItems, "Workspaces Requests History", WorkspaceRequestHistoryGrid, createDataGridOptions(TabValue.HISTORY)),
        };

        return tabContents[selectedTab];
    };

    function createDialogOptions(dialogInput) {
        const options = {};
        if (dialogInput.action === DialogAction.NEW_REQUEST) {
            options.submit = async (result) => {
                console.log(JSON.stringify(result));
                if (result.isValid) {
                    const request = result.data;
                    await Controller.get("workspaces").submitWorkspaceRequest(request);
                    Controller.get("workspaces").closeDialog();
                }
            };
        }

        if (dialogInput.action === DialogAction.EDIT_REQUEST) {
            options.submit = async (result) => {
                console.log(JSON.stringify(result));
                if (result.isValid && result.isDirty) {
                    const request = result.data;
                    request.status = RequestStatus.EDITED;
                    await Controller.get("workspaces").submitWorkspaceRequest(request);
                    Controller.get("workspaces").closeDialog();
                }
            };
        }
        return options;
    }

    return (
        <div>
            <div>
                <WorkSpacesDialogBox
                    classes={{
                        paper: styles.paper
                    }}
                    id="ringtone-menu"
                    keepMounted
                    input={dialogInput}
                    onClose={onClose}
                    options={createDialogOptions(dialogInput)}
                />
            </div>
            <div>
                <Paper className={classes.root}>
                    <div className={classes.addButton}>
                        <Button
                            id="request-new-workspace"
                            style={{ marginRight: "10px" }}
                            onClick={onNewRequest}
                            variant="outlined"
                            color="primary"
                            disabled={location.pathname === "/workspaces/new"}
                        >
                            <AddIcon />
                            New Workspace Request
                        </Button>
                        <Button
                            id="request-new-workspace"
                            style={{ marginRight: "10px" }}
                            onClick={onRefresh}
                            variant="outlined"
                            color="primary"
                            disabled={location.pathname === "/workspaces/new"}
                        >
                            <CachedIcon />
                            Refresh
                        </Button>
                    </div>
                    <Typography variant="h4" style={{ marginBottom: "20px" }}>
                        Workspace Approval Management
                    </Typography>
                    <LoadingPanel async={async}>
                        <div style={{ marginTop: "20px" }}>
                            <Tabs
                                value={selectedTab}
                                onChange={onTabSelectionChange}
                                indicatorColor="primary"
                                textColor="primary"
                                centered
                            >
                                <Tab label={`WorkSpaces (${approvedItems.length})`} value={TabValue.WORKSPACES} />
                                <Tab label={`Pending requests (${pendingItems.length})`} value={TabValue.PENDING} />
                                <Tab label={`Reviewed requests (${reviewedItem.length})`} value={TabValue.REVIEWED} />
                                <Tab label={`Requests history (${historicalItems.length})`} value={TabValue.HISTORY} />
                            </Tabs>
                            {renderSelectedTab()}
                        </div>
                    </LoadingPanel>
                </Paper>
            </div>
        </div>
    );
}

export default withStyles(styles)(WorkSpaces);
