import { Button, LinearProgress } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import * as humanizeDuration from "humanize-duration";
import React from "react";
import { useSelector } from "react-redux";
import { Controller } from "../controllers";
import LoadingPanel from "../common/LoadingPanel";
import ObjectList from "../common/ObjectList";
import CachedIcon from "@material-ui/icons/Cached";

const styles = theme => ({
  root: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: 20
  }
});

const TasksRunning = ({ classes }) => {

  const async = useSelector(state => state.runbooks._async.SYNC_RUNNING_TASKS);

  const onRefresh = e => {
    Controller.get("runbooks").refreshRunningTasks();
  };

  let headers = [
    {
      id: "duration",
      numeric: false,
      disablePadding: false,
      getCellWidget: n => {
        return <div>{humanizeDuration(n.duration, { round: true })}</div>;
      },
      label: "Duration"
    },
    {
      id: "_launchTime",
      numeric: false,
      disablePadding: false,
      getCellWidget: n => {
        return <div>{new Date(n._launchTime).toLocaleString()}</div>;
      },
      label: "Start"
    },
    {
      id: "lastUpdate",
      numeric: false,
      disablePadding: false,
      getCellWidget: n => {
        return <div>{new Date(n._lastUpdate).toLocaleString()}</div>;
      },
      label: "Last update"
    },
    { id: "category", label: "Category" },
    { id: "command", label: "Class" },
    {
      id: "method",
      label: "Method",
      getCellWidget: n => {
        return <div>{(n.args || [])[0]}</div>;
      }
    },
    {
      id: "args",
      label: "Arguments",
      getCellWidget: n => {
        return <div>{(n.args || []).slice(1).join(", ")}</div>;
      }
    },
    {
      id: "task",
      numeric: false,
      disablePadding: false,
      label: "Task"
    },
    {
      id: "percent",
      numeric: true,
      disablePadding: false,
      label: "Progress",
      getCellWidget: n => {
        return <LinearProgress variant="determinate" value={n.percent} />;
      }
    },
    {
      id: "detail",
      numeric: true,
      disablePadding: false,
      label: "Details",
      getCellWidget: n => {
        let p = Math.floor(n.percent * 1000) / 1000;
        return (
          <div>
            {n.details} {p ? `(${p}%)` : ""}
          </div>
        );
      }
    }
  ];

  return (
    <LoadingPanel async={async}>
      <div className={classes.root}>
        <Button color="primary" onClick={onRefresh}>
          <CachedIcon />
            Refresh
        </Button>
      </div>
      <ObjectList
        defaultOrderBy="_launchTime"
        defaultOrder="desc"
        title="Progress status of running tasks"
        headers={headers}
        storeData="$.runbooks.running"
      />
    </LoadingPanel>
  );
}

export default withStyles(styles)(TasksRunning);
