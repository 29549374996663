import { Paper, Tab, Tabs } from "@material-ui/core";
import React, { useState } from "react";

export default function CenteredTabs({ tabs = [], defaultTab = 0 }) {
  const [value, setValue] = useState(defaultTab);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Paper style={{ marginTop: "20px" }}>
      <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary" centered>
        {tabs.map((t, i) => (
          <Tab label={t.label} value={i} id={i} key={i}/>
        ))}
      </Tabs>
      {tabs[value].panel}
    </Paper>
  );
}
