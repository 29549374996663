import React, { useState } from "react";
import Graph from "react-graph-vis";
import { useSelector } from "react-redux";
import ObjectList from "../common/ObjectList";
import CenteredTabs from "../common/CenteredTabs";
import { AccountById } from "nxsec-shared";
import { Switch, Radio, RadioGroup, FormControl } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import { ActionColumn } from "../cloud/CloudInventoryLists";
import DomainDetailsDialog from "./DomainDetailsDialog";
import { Controller } from "../controllers";

export function DomainTree() {
  const Data = useSelector(state => state.cloudInventory);
  const graph = {
    nodes: [],
    edges: []
  };

  const options = {
    layout: {
      //hierarchical: true
    },
    edges: {
      color: "#000000"
    },
    height: "calc(100vh - 160px)",
    physics: {
      stabilization: {
        iterations: 10
      }
    }
  };

  const tree = {
    root: {
      id: "root",
      children: {}
    }
  };

  function ensureParent(id) {
    if (!id || !id.indexOf || id.indexOf(".") < 0) return;
    let parentId = id.substr(id.indexOf(".") + 1);

    if (id === parentId) return;
    if (parentId === "") {
      parentId = "root";
    }
    if (!tree[parentId]) {
      tree[parentId] = { id: parentId, children: {} };
      ensureParent(parentId);
    }
    tree[parentId].children = tree[parentId].children ? tree[parentId].children : {};
    tree[parentId].children[id] = tree[id];
  }

  Object.keys(Data.dnsEntries).forEach(d => {
    Data.dnsEntries[d].id = d;
    tree[d] = Data.dnsEntries[d];
    ensureParent(d);
  });

  function addTreeNode(el, parent) {
    // Optimize
    if (el.children && Object.keys(el.children).length === 1) {
      let child = el.children[Object.keys(el.children).pop()];
      addTreeNode(child, parent);
      return;
    }
    if (!el.id) {
      return;
    }
    el.label = el.id;
    if (parent) {
      graph.edges.push({ from: parent.id, to: el.id });
      if (parent.id !== "root") {
        el.label = el.label.substr(0, el.id.length - parent.id.length - 1);
      }
      el.label = el.label.replace(/\\052/g, "*");
      if (el.label.endsWith(".")) {
        el.label = el.label.substr(0, el.label.length - 1);
      }
    }
    graph.nodes.push(el);
    if (el.children) {
      Object.keys(el.children).forEach(c => {
        addTreeNode(el.children[c], el);
      });
    }
  }
  Object.keys(tree.root.children).forEach(d => {
    Object.keys(tree[d].children).forEach(d2 => {
      addTreeNode(tree[d2]);
    });
  });

  const errors = [];
  Object.keys(tree).forEach(dn => {
    const d = tree[dn];
    if (d.ResolvedIp && d.ResolvedIp === "NOTFOUND") {
      errors.push({ name: dn, data: d, error: "Alias not found" });
    }
  });

  const events = {
    select: function (event) {
      var { nodes } = event;
      console.log(tree[nodes.pop()]);
    }
  };
  return (
    <div>
      <Graph
        graph={graph}
        options={options}
        events={events}
        getNetwork={network => {
          //  if you want access to vis.js network api you can set the state in a parent component using this property
        }}
      />
    </div>
  );
}

const createDemainDetailsDialog = (type, title, target) => {
  if (target) {
    return (<DomainDetailsDialog
      id="ringtone-menu"
      keepMounted
      open={target.target === type}
      title={title}
      type={type}
      data={target.data}
      onClose={() => {
        Controller.get("cloudInventory").hideDomainDetailsDialog();
      }}
    />)
  }
}

export function Domains() {
  const [ipFilterOption, setIpFilterOption] = useState("ALL_IPS");
  const Data = useSelector(state => state.cloudInventory);
  Object.keys(Data.dnsEntries).forEach(dn => {
    try {
      const d = Data.dnsEntries[dn];
      d.errors = null;
      if (d.ResolvedIp && d.ResolvedIp === "NOTFOUND") {
        d.errors = d.errors || [];
        d.errors.push("Alias not found");
        d.aliasError = true;
      }

      if (d.Scan.nuxeo.defaultCredentials) {
        d.errors = d.errors || [];
        d.errors.push("Default credential on");
        d.credentialError = true;
      }
    } catch (error) { }

  });

  return (
    <div>
      {createDemainDetailsDialog("DNS", "Selected DNS Details", Data.target)}
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <FormControl row="true">
          <RadioGroup
            row
            aria-labelledby="ip-radio-group"
            name="ips"
            value={ipFilterOption}
            onChange={(event) => {
              setIpFilterOption(event.target.value)
            }}
          >
            <FormControlLabel
              value="ALL_IPS"
              name="allIPS"
              control={<Radio />}
              label="All IPs"
            />
            <FormControlLabel
              value="CREDENTIAL_ERROR"
              name="credentialErrors"
              control={<Radio />}
              label="Credential Errors Only"
            />
            <FormControlLabel
              value="ALIAS_ERROR"
              name="aliasErrors"
              control={<Radio />}
              label="Alias Errors Only"
            />
          </RadioGroup>
        </FormControl>
      </div>
      <ObjectList
        title="Domains"
        data={Object.values(Data.dnsEntries).filter(
          n => {
            return (ipFilterOption === "CREDENTIAL_ERROR" && n.credentialError) ||
              (ipFilterOption === "ALIAS_ERROR" && n.aliasError) ||
              (ipFilterOption === "ALL_IPS")
          })}
        headers={
          [
            {
              id: "Name",
              label: "DNS"
            },
            {
              id: "Value",
              label: "Value"
            },
            {
              id: "Scan",
              label: "Scan report",
              getCellWidget: n => {
                if (!n.Scan) return "";
                return (<div>
                  {Object.entries(n.Scan)
                    .filter(([key, value]) => key !== '_lastUpdate' && key !== '_creationDate' && key !== 'timestamp' && key !== 'uuid')
                    .map(([key, value]) => {
                      return <span key={key}>{`${key}: ${value.status || value}`}<br /></span>
                    })}
                </div>)
              }
            },
            {
              id: "errors",
              label: "Error",
              getCellWidget: n => {
                if (!n.errors) return ""
                return (<div>
                  {n.errors.map((error, index) => {
                    return (<span key={index}>{error}<br /></span>)
                  })}
                </div>)
              }
            },
            ActionColumn("ec2", n => {
              Controller.get("cloudInventory").showDomainDetailsDialog("DNS", n);
            })
          ]}
      />
    </div>
  );
}

export function IPs() {
  const [errorIPSOnly, setErrorIPSOnly] = useState(false);
  const [privateIp, setPrivateIp] = useState(false);
  const [natGateway, setNatGateway] = useState(false);
  const Data = useSelector(state => state.cloudInventory);

  Object.keys(Data.publicIps).forEach(n => {
    try {
      if (!Data.publicIps[n].Value) {
        Data.publicIps[n].Value = n;
      }
      if (Data.publicIps[n].Scan.nuxeo.defaultCredentials) {
        Data.publicIps[n].error = "Default credentials on";
      }
    } catch { }
  });
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        {createDemainDetailsDialog("IPS","Selected IP Details",Data.target)}
        <FormGroup row>
          <FormControlLabel
            control={
              <Switch
                checked={natGateway}
                onChange={() => {
                  setNatGateway(!natGateway);
                }}
                name="checkedA"
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
            }
            label="Include Nat Gateways"
          />
          <FormControlLabel
            control={
              <Switch
                checked={privateIp}
                onChange={() => {
                  setPrivateIp(!privateIp);
                }}
                name="checkedB"
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
            }
            label="Include Private IPs"
          />
          <FormControlLabel
            control={
              <Switch
                checked={errorIPSOnly}
                onChange={() => {
                  setErrorIPSOnly(!errorIPSOnly);
                }}
                name="checkedA"
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
            }
            label="Only Error IPs"
          />
        </FormGroup>
      </div>

      <ObjectList
        title="Nuxeo IPs Footprint"
        headers={[
          {
            label: "IP", id: "Value"
          },
          {
            label: "Dns", id: "Dns",
            getCellWidget: n => {
              if (!n.Dns || n.Dns.length === 0) return ;
              return (<div>
                {n.Dns.map((dns, index) => {
                  return <span key={index}>{dns} <br /></span>
                })}
              </div>)
            }
          },
          {
            label: "Account",
            id: "Account",
            getCellValue: n => {
              let acc = AccountById(n.Account);
              return acc ? acc.Alias : n.Account;
            }
          },
          { label: "Region", id: "Region" },
          { label: "Provider", id: "Provider" },
          ActionColumn("Details", n => {
            console.log(n)
            Controller.get("cloudInventory").showDomainDetailsDialog("IPS", n);
          })
        ]}
        data={Object.values(Data.publicIps).filter(
          n => {
            return (!n.IsPrivate || (n.IsPrivate && privateIp)) &&
              (!n.NatGateway || (n.NatGateway && natGateway)) &&
              (errorIPSOnly ? n.error : true);
          })}
      />
    </div>
  );
}

export default function Panel() {
  return (
    <div>
      <CenteredTabs
        tabs={[
          {
            label: "Domain tree",
            panel: <DomainTree />
          },
          {
            label: "Domains",
            panel: <Domains />
          },
          {
            label: "IPs",
            panel: <IPs />
          }
        ]}
      />
    </div>
  );
}
