
import { toast } from "react-toastify";
import { Controller } from "./Controller";


export enum TabValue {
    WORKSPACES = "WORKSPACES",
    PENDING = "PENDING",
    REVIEWED = "REVIEWED",
    HISTORY = "HISTORY"
};

enum WorkspaceApiStatusCode {
    PENDING = 1,
    OK = 2,
    PRIVILEGE_ERROR = 403,
    JIRA_CONFLICT = 450,
    USER_AREADY_EXIST_PENDING = 451,
    USER_AREADY_EXIST_REVIEWED = 452,
    SAME_REVIEWER_REQUESTER = 453,
    INVALID_REQUEST = 454,
    REQUEST_NOT_FOUND = 455,
    INTERNAL_SERVER_ERROR = 500,
    UNKNOWN = 0
}


export enum WorkSpaceState {
    NONE = "NONE",
    ALL = "ALL",
    //-----------------
    PENDING = "PENDING",
    IN_PROGRESS = "IN_PROGRESS",
    AVAILABLE = "AVAILABLE",
    STOPPED = "STOPPED",
    TIMEOUT = "TIMEOUT",
    ERROR = "ERROR",
    //------------------------
    TERMINATING = "TERMINATING",
    TERMINATED = "TERMINATED",
}

export enum RequestStatus {
    NONE = "NONE",
    ALL = "ALL",
    //----------------
    REQUESTED = "REQUESTED",
    EDITED = "EDITED",
    DELETED = "DELETED",
    //------------------
    APPROVED = "APPROVED",
    REBUILD = "REBUILD",
    REVOKED = "REVOKED",
    REJECTED = "REJECTED",
}

const workspaceFetchItems = ["uiconfig", "pending", "history", "reviewed"];

enum DispatchType {
    UPDATE_STATE = "UPDATE_STATE_DISPATCH_ACTION",
    DIALOG = "WORKSPACE_DIALOG_DISPATCH_ACTION",
    API = "WORKSPACE_API_DISPATCH_ACTION"
}

class StateUpdateDispatchAction {
    type: DispatchType;
    state: any;
}



class ApiRequestDispatchAction {
    type: DispatchType;
    apiStatusCode: WorkspaceApiStatusCode;
    requestStatus: RequestStatus;
    syncItems: string[];
    result: any;

}

export enum DialogAction {
    CLOSE,
    NEW_REQUEST,
    EDIT_REQUEST,
    WARNING,
    WORKSPACE_DETAILS
}

class DialogDispatchAction {
    type: DispatchType;
    dlgAction: DialogAction;
    data: any;
}

export interface RequestDetails {
    requester: string;
    comment: string;
    requestedOn: number;
    modifiedOn: number;
}

export interface ReviewDetails {
    reviewer: string;
    comment: string;
    reviewedOn: number;
}

export interface WorkspaceDetails {
    user: string;
    email: string;
    directoryId: string;
    bundleId: string;
    tags: {
        billingCategory: string;
        billingSubcategory: string;
    };
    emailInvitation: boolean;
    slackMessage: boolean;
}

export class WorkspaceRequest {
    uuid: string;
    status: RequestStatus;
    jiraNumber: string;
    request: RequestDetails;
    workspace: WorkspaceDetails;
    createdOn: number;
    updatedOn: number;

    constructor({
        status = RequestStatus.NONE,
        jiraNumber = "",
        directoryId = "",
        bundleId = "",
        billingCategory = "",
        billingSubCategory = ""
    } = {}) {
        this.uuid = "";
        this.status = status;
        this.jiraNumber = jiraNumber;
        this.request = {
            requester: "",
            comment: "",
            requestedOn: 0,
            modifiedOn: 0
        };
        this.workspace = {
            user: "",
            email: "",
            directoryId: directoryId,
            bundleId: bundleId,
            tags: {
                billingCategory: billingCategory,
                billingSubcategory: billingSubCategory
            },
            emailInvitation: true,
            slackMessage: true
        };
        this.createdOn = 0;
        this.updatedOn = 0;
    }

    static isEqual(self: WorkspaceRequest, obj: WorkspaceRequest): boolean {
        return (
            self.jiraNumber === obj.jiraNumber &&
            self.workspace.directoryId === obj.workspace.directoryId &&
            self.workspace.bundleId === obj.workspace.bundleId &&
            self.workspace.tags.billingCategory === obj.workspace.tags.billingCategory &&
            self.workspace.tags.billingSubcategory === obj.workspace.tags.billingSubcategory
        );
    }
}

export class WorkspaceReviewRequest extends WorkspaceRequest {
    review: ReviewDetails;
    constructor(status: RequestStatus) {
        super({ status });
        this.review = {
            reviewer: "",
            comment: "",
            reviewedOn: 0
        };
    }
}

export class DialogInput {
    action: DialogAction;
    constructor(action) {
        this.action = action;
    }
}

export class WorkspaceDialogInput extends DialogInput {
    uiconfig: any;
    data: WorkspaceRequest;
    constructor(action = DialogAction.CLOSE, uiconfig = {}, data = null) {
        super(action)
        this.data = data;
        this.uiconfig = uiconfig;
    }
}

export class ConfirmDialogInput extends DialogInput {
    options: any;
    constructor(action = DialogAction.CLOSE, options) {
        super(action)
        this.options = options;
    }
}

export class WorkspaceDetailsDialogInput extends DialogInput {
    uiconfig: any;
    data: WorkspaceReviewRequest;
    constructor(action = DialogAction.CLOSE, uiconfig = {}, data = null) {
        super(action)
        this.data = data;
        this.uiconfig = uiconfig;
    }
}



export class WorkspaceDialogResult {
    action: DialogAction;
    data: WorkspaceRequest;
    isValid: boolean;
    isDirty: boolean;

    constructor(action: DialogAction) {
        this.action = action;
        this.data = new WorkspaceRequest();
        this.isValid = false;
        this.isDirty = false;
    }
}



class WorkSpacesController extends Controller {
    constructor() {
        const initialState = {
            selectedTab: TabValue.WORKSPACES,
            sorting: {
                [TabValue.WORKSPACES]: { order: "desc", orderBy: null },
                [TabValue.PENDING]: { order: "desc", orderBy: null },
                [TabValue.REVIEWED]: { order: "desc", orderBy: null },
                [TabValue.HISTORY]: { order: "desc", orderBy: null },
            },
            filter: {
                [TabValue.WORKSPACES]: { filterBy: null },
                [TabValue.PENDING]: { filterBy: null },
                [TabValue.REVIEWED]: { filterBy: null },
                [TabValue.HISTORY]: { filterBy: null },
            },
            dialogInput: new WorkspaceDialogInput(),
            items: workspaceFetchItems.reduce((acc, item) => {
                acc[item] = item === "uiconfig" ? {} : [];
                return acc;
            }, {} as { [key: string]: any })
        };

        super("workspaces", initialState);
        this.registerRoute({ path: "/workspaces", strict: false, exact: true }, this.onPageLoad);
    }

    onPageLoad() {
        this.fetchWorkSpaceItems();
    }

    fetchWorkSpaceItems(wsItems: string[] = workspaceFetchItems) {
        this.asyncAction("FETCH_WORKSPACE_ITEMS", async () => {
            const responses = await Promise.all(wsItems.map(item => this.fetchWorkSpaceItem(item)));
            const fetchedItems = responses.reduce((acc, curr) => {
                return { ...acc, ...curr };
            }, {});
            return { items: fetchedItems };
        });
    }

    async fetchWorkSpaceItem(item: string) {
        try {
            const response = await this.ajax(`/workspaces/${item}`);
            return { [item]: response };
        } catch (error) {
            console.error(`Failed to fetch ${item} request`, error);
            return { [item]: item === "uiconfig" ? {} : [] };
        }
    }

    submitWorkspaceRequest(request: WorkspaceRequest) {
        Controller.dispatch({ type: DispatchType.API, apiStatusCode: WorkspaceApiStatusCode.PENDING, requestStatus: request.status } as ApiRequestDispatchAction);
        this.asyncAction("SUBMIT_WORKSPACE_REQUEST", async () => {
            await this.ajax("/workspaces", "POST", request)
                .then(res => {
                    Controller.dispatch({ type: DispatchType.API, apiStatusCode: WorkspaceApiStatusCode.OK, requestStatus: request.status, syncItems: workspaceFetchItems } as ApiRequestDispatchAction);
                })
                .catch(err => {
                    const apiStatusCode: WorkspaceApiStatusCode = this.getApiStatusCode(err);
                    Controller.dispatch({ type: DispatchType.API, apiStatusCode, requestStatus: request.status, syncItems: workspaceFetchItems } as ApiRequestDispatchAction);
                });
        });
    }

    reviewWorkspaceRequest(request: WorkspaceRequest) {
        console.log(`reviewWorkspaceRequest: ${request.status}`)
        Controller.dispatch({ type: DispatchType.API, apiStatusCode: WorkspaceApiStatusCode.PENDING, requestStatus: request.status } as ApiRequestDispatchAction);
        this.asyncAction("REVIEW_WORKSPACE_REQUEST", async () => {
            await this.ajax("/workspaces/review", "POST", request)
                .then(res => {
                    Controller.dispatch({ type: DispatchType.API, apiStatusCode: WorkspaceApiStatusCode.OK, requestStatus: request.status, syncItems: workspaceFetchItems });
                })
                .catch(err => {
                    const apiStatusCode: WorkspaceApiStatusCode = this.getApiStatusCode(err);
                    Controller.dispatch({ type: DispatchType.API, apiStatusCode, requestStatus: request.status, syncItems: workspaceFetchItems } as ApiRequestDispatchAction);
                });
        });
    }

    getApiStatusCode(error) {
        let code = Object.values(WorkspaceApiStatusCode).includes(error.code) ? (error.code as WorkspaceApiStatusCode) : WorkspaceApiStatusCode.UNKNOWN;
        return code;
    }

    getApiStatusMessage(apiStatusCode: WorkspaceApiStatusCode) {
        const workspaceApiStatusMessages = [
            { code: WorkspaceApiStatusCode.PENDING, message: "Your request is awaiting approval." },
            { code: WorkspaceApiStatusCode.OK, message: "The request has been successfully completed." },
            { code: WorkspaceApiStatusCode.PRIVILEGE_ERROR, message: "Permission denied: insufficient privileges." },
            { code: WorkspaceApiStatusCode.JIRA_CONFLICT, message: "There is a conflict with an existing JIRA issue." },
            { code: WorkspaceApiStatusCode.USER_AREADY_EXIST_PENDING, message: "A request for this user is already pending review." },
            { code: WorkspaceApiStatusCode.USER_AREADY_EXIST_REVIEWED, message: "A request for this user has already been approved." },
            { code: WorkspaceApiStatusCode.SAME_REVIEWER_REQUESTER, message: "The same person cannot be both the requester and reviewer." },
            { code: WorkspaceApiStatusCode.INVALID_REQUEST, message: "The request format is invalid." },
            { code: WorkspaceApiStatusCode.REQUEST_NOT_FOUND, message: "The specified request could not be found." },
            { code: WorkspaceApiStatusCode.INTERNAL_SERVER_ERROR, message: "An error occurred on the server." },
            { code: WorkspaceApiStatusCode.UNKNOWN, message: "An unspecified error has occurred." }
        ];        
        
        const status = workspaceApiStatusMessages.find(status => status.code === apiStatusCode);
        return status ? status.message : "Workspace Api status code not recognized.";
    }

    generateToastMessage(apiStatusCode: WorkspaceApiStatusCode, requestStatus: RequestStatus) {
        const requestStatusMessages = {
            [RequestStatus.REQUESTED]: "Submitting request for a new workspace...",
            [RequestStatus.EDITED]: "Submitting updates to the workspace request...",
            [RequestStatus.DELETED]: "Submitting request to delete the workspace...",
            [RequestStatus.REJECTED]: "Submitting request rejection for the workspace...",
            [RequestStatus.APPROVED]: "Submitting workspace approval request...",
            [RequestStatus.REVOKED]: "Submitting request to revoke the workspace...",
        };

        if (apiStatusCode === WorkspaceApiStatusCode.PENDING) {
            return { message: requestStatusMessages[requestStatus] || "Processing request request...", error: false }
        }

        if (apiStatusCode === WorkspaceApiStatusCode.OK) {
            return { message: `Workspace request successfully submitted!`, error: false }
        }

        return { message: this.getApiStatusMessage(apiStatusCode), error: true }
    }

    onWORKSPACE_API_DISPATCH_ACTION(state: any, action: ApiRequestDispatchAction) {
        const { apiStatusCode, requestStatus } = action;
        const { message, error } = this.generateToastMessage(apiStatusCode, requestStatus);
        toast.dismiss();
        if (!error) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000
            });
        } else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 5000
            });
        }

        if (action.result) {
            state = { ...state, ...action.result }
        }

        if (apiStatusCode === WorkspaceApiStatusCode.OK && requestStatus === RequestStatus.REQUESTED) {
            state = { ...state, selectedTab: TabValue.PENDING }
        }
        return state;
    }

    afterWORKSPACE_API_DISPATCH_ACTION(action: ApiRequestDispatchAction) {
        if (action && action.syncItems) {
            this.fetchWorkSpaceItems(action.syncItems);
        }
    }

    //-------------------------[For Dialogs]--------------------------
    openDialog(action: DialogAction, data: any = null) {
        Controller.dispatch({ type: DispatchType.DIALOG, dlgAction: action, data });
    }

    closeDialog() {
        Controller.dispatch({ type: DispatchType.DIALOG, dlgAction: DialogAction.CLOSE });
    }

    onWORKSPACE_DIALOG_DISPATCH_ACTION(state: any, action: DialogDispatchAction) {
        let dialogInput = null;
        if (action.dlgAction === DialogAction.WARNING) {
            dialogInput = new ConfirmDialogInput(action.dlgAction, action.data);
        } else if (action.dlgAction === DialogAction.WORKSPACE_DETAILS) {
            dialogInput = new WorkspaceDetailsDialogInput(action.dlgAction, state.items.uiconfig, action.data);
        } else {
            dialogInput = new WorkspaceDialogInput(action.dlgAction, state.items.uiconfig, action.data);
        }
        return {
            ...state,
            dialogInput
        };
    }


    updateState(state: any) {
        Controller.dispatch({ type: DispatchType.UPDATE_STATE, state: state } as StateUpdateDispatchAction);
    }

    onUPDATE_STATE_DISPATCH_ACTION(state: any, action: StateUpdateDispatchAction) {
        const updatedState = {
            ...state,
            ...action.state
        };

        return updatedState;
    }
}

export { WorkSpacesController };
