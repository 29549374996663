import { CircularProgress, Paper, Tab, Tabs, Typography } from "@material-ui/core";
import React from "react";
import { Controller } from "../controllers";
export default function TypeDocReport() {
  const [value, setValue] = React.useState("api");
  const [loading, setLoading] = React.useState("loading");
  const [height, setHeight] = React.useState("500px");

  const handleChange = (event, newValue) => {
    setLoading(true);
    setValue(newValue);
  };
  // Hack to resize the iframe to its content
  const iframeLoaded = evt => {
    window.onmessage = function(event) {
      if (event.data && event.data.nxsecIFrameHeight) {
        setHeight(event.data.nxsecIFrameHeight);
      }
    };
    setLoading(false);
    evt.target.contentWindow.postMessage({ nxsecGetHeight: true }, "*");
  };
  let env = "prod";
  let match = /^https:\/\/((?<pr>[^\\.]+)\.)?(?<env>[^\\.]+)\.soc\.nuxeo\.com/g.exec(window.location.origin);
  if (match) {
    if (match.groups.pr) {
      env = `PRs/${match.groups.pr}`;
    } else {
      env = match.groups.env;
    }
  } else if (window.location.origin.match("http://localhost:3000")) {
    env = "dev";
  }
  let turi = `${Controller.endpoint}/static/typedoc/${env}/${value}/index.html`;
  let loadingPanel;
  // Cannot hide as it blocks SVG rendering so setting height to 0px
  let factHeight = loading ? "0px" : height;
  if (loading) {
    loadingPanel = (
      <div
        style={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          minHeight: "300px",
          width: "100%",
          flexDirection: "column"
        }}
      >
        <CircularProgress size={32} />
        <Typography style={{ marginTop: "20px", color: "#999" }}>Loading</Typography>
      </div>
    );
  }
  return (
    <Paper style={{ marginTop: "20px" }}>
      <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary" centered>
        <Tab label="API Doc" value="api" />
        <Tab label="Tasks Doc" value="tasks" />
      </Tabs>
      {loadingPanel}
      <iframe
        onLoad={iframeLoaded}
        src={turi}
        style={{
          width: "100%",
          height: factHeight,
          maxHeight: "8325px",
          border: "0px"
        }}
        title="TypeDoc Reports"
      />
    </Paper>
  );
}