import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import LoadingPanel from "../common/LoadingPanel";
import Chart from "react-apexcharts";
import { GenerateDownloadButton } from "../LogsPanel";
import { Controller } from "../controllers";

const ApexChart = props => {
  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({
    yaxis: { labels: { rotate: -15 } }, // beyond 50 there is a yaxis offset issue TODO
    chart: {
      animations: {
        enabled: false
      },
      height: 350,
      type: "heatmap"
    },
    tooltip: {
      x: {
        show: true,
        format: "dd MMM",
        formatter: y => y
      }
    },
    dataLabels: {
      enabled: false
    },
    colors: ["#008FFB"],
    title: {
      text: "Okta presence, valued by number of distinct apps used"
    }
  });

  // Compute graph based on heatmap prop
  useEffect(() => {
    let { users = [], data: metrics = {} } = props.heatmap;
    if (users && metrics) {
      let orderedUsers = [];
      for (let u in users) {
        orderedUsers.push([u, users[u]]);
      }
      orderedUsers.sort((a, b) => a[1] - b[1]);
      let totalPerDay = {};
      for (let day in metrics) {
        for (let u in metrics[day]) {
          totalPerDay[day] = (totalPerDay[day] || 0) + metrics[day][u];
        }
      }
      const newSeries = [];
      orderedUsers.forEach(([name, totalPerUserDay]) => {
        let data = [];
        for (let day in metrics) {
          let labelDay = `${day} (${totalPerDay[day]})`;
          let d = { x: labelDay, y: 0 };
          for (let u in metrics[day]) {
            if (name !== u) continue;
            d = { x: labelDay, y: metrics[day][u] };
          }
          data.push(d);
        }
        newSeries.push({ name: `${name} (${totalPerUserDay})`, data });
      });
      setSeries(newSeries);
      setOptions(o => ({ ...o, chart: { ...o.chart, height: newSeries.length * 20 } }));
    }
  }, [props.heatmap]);

  return (
    <div id="chart">
      <div style={{ marginTop: 20, fontSize: 12 }}>Exports :</div>
      <GenerateDownloadButton title="CSV" download={callback => Controller.get("health").getHeatmapCSV(callback)} />
      <Chart options={options} series={series} type={options.chart.type} height={options.chart.height} />
    </div>
  );
};

const HealthTeam = () => {
  const async = useSelector(state => state.health._async.SYNC_TEAM_HEALTH);
  const heatmap = useSelector(state => state.health.heatmap);
  return (
    <LoadingPanel async={async}>
      {(heatmap && <ApexChart heatmap={heatmap} />) || <div>No data found.</div>}
    </LoadingPanel>
  );
};

export default HealthTeam;
