import { toast } from "react-toastify";
import { Controller } from "./Controller";

class RunbooksController extends Controller {
  constructor() {
    super("runbooks", { report: {}, current: {}, list: [], scheduled: [], running: [], manualTaskState: {} });
    this.registerRoute({ path: "/security-automation", strict: false, exact: true }, this.syncAllRunbooks);
    this.registerRoute({ path: "/security-automation/:uuid", strict: false, exact: true }, this._fetchRoute);
  }

  _fetchRoute(params) {
    this.syncAllRunbooks();
    if (params.uuid) {
      this.get(params.uuid);
    }
  }

  async cancelScheduledTask(data: any) {
    const action = "CancelScheduledTask";
    this.asyncAction("SYNC_RUNBOOKS", async () => {
      await this.ajax(
        "/tasks/cancelScheduledTask",
        "POST",
        data,
        { action } // action is important to be triggered in case of async MFA
      ).then(r => {
        if (r && r.cancelled) {
          // MFA not expired, immediate execution
          Controller.dispatch({ type: action });
        }
      });
    });
  }

  onCancelScheduledTask(state, action) {
    toast.success(`Cancelling scheduled task successful!`, {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 10000
    });
    return state;
  }

  afterCancelScheduledTask(state, action) {
    this.refreshScheduledTasks();
    return state;
  }

  openConfirmationDialog(task) {
    Controller.dispatch({ type: "OPEN_TASK_CONFIRMATION_DIALOG", task })
  }

  onOPEN_TASK_CONFIRMATION_DIALOG(state, data) {
    let manualTaskState = { openConfirmation: true, data: data.task };
    console.log(`OPEN: ${JSON.stringify(manualTaskState)}`)
    return { ...state, manualTaskState };
  }

  closeConfirmationDialog() {
    Controller.dispatch({ type: "CLOSE_TASK_CONFIRMATION_DIALOG" })
  }

  onCLOSE_TASK_CONFIRMATION_DIALOG(state) {
    let manualTaskState = {};
    console.log(`CLOSE: ${JSON.stringify(manualTaskState)}`)
    return { ...state, manualTaskState };
  }

  async invokeManualTask(data: any) {
    Controller.dispatch({ type: "MANUAL_TASK_RUNNING_STATUS", status: "INVOKED" })
    this.asyncAction("SYNC_RUNBOOKS", async () => {
      await this.ajax(
        "/tasks/executeTask",
        "POST",
        data
      ).then(res => {
        if (res.triggred) {
          Controller.dispatch({ type: "MANUAL_TASK_RUNNING_STATUS", status: "TRIGGERED" })
        }
      }).catch(err => {
        if (err.code === 403) {
          Controller.dispatch({ type: "MANUAL_TASK_RUNNING_STATUS", status: "ERROR_PRIVILEGE" })
        }
        else {
          Controller.dispatch({ type: "MANUAL_TASK_RUNNING_STATUS", status: "ERROR" })
        }
      })
    })
  }

  onMANUAL_TASK_RUNNING_STATUS(state, data) {
    let status = data.status;
    let { manualTaskState } = state;

    switch (status) {
      case "INVOKED": {
        manualTaskState.openConfirmation = false;
        manualTaskState = { ...manualTaskState, status }
        break;
      }
      case "TRIGGERED": {
        manualTaskState = {};
        toast.success(`Task successfully triggered!`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000
        });
        break;
      }
      case "ERROR": {
        manualTaskState = {};
        toast.error("Task triggering failed due to server error.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000
        });
        break;
      }
      case "ERROR_PRIVILEGE": {
        manualTaskState = {};
        toast.error("Access denied. You do not have the necessary privileges to proceed. Please contact Security-Admin.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 6000
        });
        break;
      }
    }
    console.log(`TASK-STATUS: ${JSON.stringify(manualTaskState)}`)
    return { ...state, manualTaskState }
  }

  syncAllRunbooks() {
    this.refreshRunbooks();
    this.refreshScheduledTasks();
    this.refreshRunningTasks();
  }

  refreshRunbooks() {
    this.asyncAction(
      "SYNC_RUNBOOKS",
      async () => {
        await Controller.get("users").waitInit();
        return { list: await this.ajax("/dashboard/runbooks") };
      },
      () => {
        this.setInitialized();
      }
    );
  }

  refreshScheduledTasks() {
    this.asyncAction("SYNC_SCHEDULED_TASKS", async () => {
      await Controller.get("users").waitInit();
      let {
        crontab,
        scheduled: { tasks = {} }
      } = await this.ajax("/dashboard/scheduled-tasks");
      let scheduled = [];
      for (let i in tasks) {
        scheduled.push(tasks[i]);
      }
      return { scheduled, crontab };
    });
  }

  refreshRunningTasks() {
    this.asyncAction("SYNC_RUNNING_TASKS", async () => {
      await Controller.get("users").waitInit();
      let running = (await this.ajax("/dashboard/running-tasks")) || [];
      running = running.map(o => {
        return {
          ...o,
          lastUpdate: new Date(o._lastUpdate).getTime(),
          duration: Date.now() - new Date(o._launchTime).getTime()
        };
      });
      return { running };
    });
  }

  get(uuid) {
    this.asyncAction("GET_RUNBOOK", async () => {
      await this.waitInit();
      let currentRunbook = {};
      let runbooks = this.getLocalState().list;
      currentRunbook = runbooks.filter(runbook => runbook.uuid === uuid);
      return { current: currentRunbook[0] };
    });
  }
}
export { RunbooksController };
