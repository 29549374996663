import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import { Dropdown, Table, Checkbox } from "semantic-ui-react";

const styles = theme => ({
    
    amiContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        margin: "10px",
        width: "85vw",
        flexWrap: "wrap"
    },
    amiHeader: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "20px"
    },
    amiStatus: {
        color: "red",
        fontWeight: "bold"
    },
    amiLogo: {
        width: "50px",
        height: "50px"
    },
    amiInfos: {
        padding: "20px"
    },
    dropdown: {
        marginTop: "50px",
        marginRight: "20px",
        "& @media(max-width: 746px)": {
            margin: "50px"
        }
    }
});

export const SelectedAccountTable = ({ ami, selectedAccount }) => {
    return (
        <Table celled stripped="true" size="large" center="true" className="dropdownContent">
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell textAlign="center">
                        Region
                  </Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">
                        Name
                  </Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">Built on</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">Deprecated</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {Object.keys(ami.latests[selectedAccount]).map((item, i) => {
                    let date = new Date(ami.latests[selectedAccount][item].date).toDateString();
                    return (
                        <Table.Row key={i}>
                            <Table.Cell textAlign="center">{item}</Table.Cell>
                            <Table.Cell textAlign="center">
                                {ami.latests[selectedAccount][item].ami}
                            </Table.Cell>
                            <Table.Cell textAlign="center">{date}</Table.Cell>
                            <Table.Cell textAlign="center">
                                <Checkbox readOnly checked={ami.latests[selectedAccount][item].deprecated} />
                            </Table.Cell>
                        </Table.Row>
                    );
                })}
            </Table.Body>
        </Table>
    )
}

const AmazonMachinesImage = ({ classes, ami }) => {
    const [selectedAccount, setSelectedAccount] = useState("");
    ami.metadatas = ami.metadatas || {
        name: "Empty name",
        description: "Empty description",
        icon: ""
    };
    const accountSelect = (evt, { value }) => {
        setSelectedAccount(value);
    }
    return (
        <Paper className={classes.root}>
            <div className={classes.amiContainer}>
                <div className={classes.amiHeader}>
                    <img className={classes.amiLogo} src={ami.metadatas.icon} alt="amis logo" />
                    <div className={classes.amiInfos}>
                        <h3>{ami.metadatas.name} <sup className={classes.amiStatus}>{ami.metadatas.deprecationMessage}</sup></h3>
                        <h4>{ami.metadatas.description}</h4>
                    </div>
                </div>
                <Dropdown
                    className={classes.dropdown}
                    placeholder="Account Selector"
                    selection
                    options={Object.keys(ami.latests).map(account => {
                        return {
                            text: account,
                            value: account
                        };
                    })}
                    onChange={accountSelect}
                    clearable
                />
            </div>
            {selectedAccount !== "" &&
                <SelectedAccountTable
                    ami={ami}
                    selectedAccount={selectedAccount}
                />
            }
        </Paper>
    )
};

export default withStyles(styles)(AmazonMachinesImage);
