import { Controller } from "./Controller";
import { toast } from "react-toastify";

class ProxySSLController extends Controller {
  constructor() {
    super("proxy", { proxies: [], domains: {}, current: {} });
    this.registerRoute({ path: "/proxies-ssl/:id", strict: false, exact: true }, this._fetchProxyRoute);
  }

  afterLOGIN_SUCCESS() {
    this.asyncAction(
      "SYNC_PROXIES",
      async (dispatch, getState) => {
        let proxiesIndex = await this.ajax("/proxies/index");
        return { proxies: proxiesIndex };
      },
      () => {
        this.setInitialized();
      }
    );
  }

  _fetchProxyRoute(params) {
    this.get(params.id);
  }

  get(uuid) {
    this.asyncAction("GET_PROXY", async (dispatch, getState) => {
      await this.waitInit();
      if (uuid === "new") {
        return {
          current: {
            domain: "",
            type: "",
            replaces: [],
            basePath: "",
            servers: [],
            aliases: [],
            uuid: ""
          }
        };
      } else {
        return { current: await this.ajax(`/proxies/${uuid}`) };
      }
    });
  }

  addProxy(info, callback) {
    this.asyncAction(
      "ADD_PROXY",
      async (dispatch, getState) => {
        let proxies = this.getLocalState().proxies.slice(0);
        proxies.push(
          await this.ajax("/proxies", "POST", {
            ...info,
            uuid: info.domain
          })
          .catch(err => {
            if (err.code === 403) {
              toast.error("Access denied. You do not have the necessary privileges to proceed. Please contact Security-Admin.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 6000
              });
            }
          })
        );
        
        return { proxies: proxies };
      },
      callback
    );
  }

  updateProxy(currentProxy, callback) {
    this.asyncAction(
      "UPDATE_PROXY",
      async (dispatch, getState) => {
        let proxies = this.getLocalState().proxies.slice(0);
        await this.ajax(`/proxies/${currentProxy.uuid}`, "PATCH", currentProxy)
        .catch(err => {
          if (err.code === 403) {
            toast.error("Access denied. You do not have the necessary privileges to proceed. Please contact Security-Admin.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 6000
            });
          }
        });
        proxies[proxies.findIndex(proxy => proxy.uuid === currentProxy.uuid)] = currentProxy;
        return { proxies: proxies, current: currentProxy };
      },
      callback
    );
  }

  deleteProxy(proxyToDelete, callback) {
    this.asyncAction(
      "DELETE_PROXY",
      async (dispatch, getState) => {
        let proxies = this.getLocalState().proxies.slice(0);
        await this.ajax(`/proxies/${proxyToDelete.uuid}`, "DELETE")
        .catch(err => {
          if (err.code === 403) {
            toast.error("Access denied. You do not have the necessary privileges to proceed. Please contact Security-Admin.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 6000
            });
          }
        });
        proxies = proxies.filter(proxy => proxy.uuid !== proxyToDelete.uuid);
        return { proxies: proxies };
      },
      callback
    );
  }
}

export { ProxySSLController };
